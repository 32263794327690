<template>
  <div id="ReservationPayments" v-if="$props.reservation">
    <b-card
        footer-class="p-0 border-0"
        :body-class="[
        { 'payed' : $props.reservation.value.amount === $props.reservation.payed.amount },
        { 'notPayed' : $props.reservation.value.amount > $props.reservation.payed.amount },
        { 'overPaid' : $props.reservation.value.amount < $props.reservation.payed.amount },
        'd-flex align-items-center justify-content-center'
      ]"
        class="w-100 mb-2 shadow-sm overflow-hidden"
    >
      <div>
        <div class="text-white totalAmounttext text-center">
          <span v-html="$props.reservation.value.currency_sign"></span>
          {{ $props.reservation.value.amount | money($props.reservation.value.currency_id) }}
        </div>
        <div class="text-white">
          <div id="payed">{{ $t('betalingen.betaald') }}: <span v-html="$props.reservation.payed.currency_sign"></span>
            {{ $props.reservation.payed.amount | money($props.reservation.value.currency_id) }}
          </div>
          <div id="amount">{{ $t('betalingen.restant') }}: <span v-html="$props.reservation.value.currency_sign"></span>
            {{
              ($props.reservation.value.amount - $props.reservation.payed.amount) | money($props.reservation.value.currency_id)
            }}
          </div>
        </div>
      </div>

      <template #footer>
        <b-button-group class="w-100">
          <b-button :disabled="!hasRights" squared
                    @click="showPayment()">{{ $t('betalingen.betalingen') }}
          </b-button>
          <b-button squared @click="showDocuments()">{{ $t('betalingen.documenten') }}</b-button>
        </b-button-group>
      </template>
    </b-card>
    <b-modal
        id="paymentModal"
        :title="$t('betalingen.betalingen') + ' ' + $t('alg.voor') + ' ' + $t('alg.reserveringklein') + ' ' + $props.reservation.internalkey"
        body-class="p-0"
        :hide-footer="true"
    >
      <Payments :reservation="$props.reservation"
                v-on:reservationsOverviewRefresh="$emit('reservationsOverviewRefresh')"></Payments>
    </b-modal>
    <b-modal
        id="bookingDocsModal"
        :title="$t('alg.documenten') + ' ' + $t('alg.voor') + ' ' + $t('alg.reserveringklein') + ' ' + $props.reservation.internalkey"
        :hide-footer="true"
    >
      <div class="mb-3 pb-2 border-bottom" v-if="bookingInvoices.length > 0">
        <h6 class="font-weight-bold">{{ $t('betalingen.factuur') }}</h6>
        <b-row
            v-for="(doc) in bookingInvoices"
            v-bind:key="doc.title"
        >
          <b-col cols="">
            {{ doc.title }}
          </b-col>
          <b-col cols="2" class="text-center">
            <b-button @click="downloadPDF(doc)" size="sm">
              <b-icon icon="download"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="mb-3 pb-2 border-bottom" v-if="bookingDocs.length > 0">
        <h6 class="font-weight-bold">{{ $t('betalingen.externedocs') }}</h6>
        <b-row
            v-for="(doc) in bookingDocs"
            v-bind:key="doc.title"
            class="mb-1"
        >
          <template v-if="doc.type === 'extdoc'">
            <b-col cols="">
              {{ doc.title }}
            </b-col>
            <b-col cols="2" class="text-center">
              <b-button @click="downloadPDF(doc)" size="sm">
                <b-icon icon="download"></b-icon>
              </b-button>
            </b-col>
          </template>
        </b-row>
      </div>
      <div class="mb-3 pb-2 border-bottom" v-if="internalTickets.length > 0">
        <h6 class="font-weight-bold">Tickets</h6>
        <b-row
            v-for="(doc) in internalTickets"
            v-bind:key="doc.title"
            class="mb-1"
        >
          <b-col cols="">
            {{ doc.title }}
          </b-col>
          <b-col cols="2" class="text-center">
              <b-icon  scale="1.5" :variant="doc.used ? 'success' : 'primary'" :icon="doc.used ? 'check-square' : 'square'"></b-icon>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ReservationsService from '@/services/reservations.service'

import Payments from "@/components/Payments"

export default {
  props: ['reservation', 'openPayment'],
  name: 'ReservationPayments',
  components: {Payments},
  data() {
    return {
      payments: null,
      paymentMethods: null,
      selectedPaymentMethod: null,
      modalTitle: null,
      transferAmount: 0,
      bookingDocs: [],
      bookingInvoices: [],
      internalTickets: []
    };
  },
  computed: {
    hasRights() {
      return this.$store.getters['user/hasRight']("reservation_crud");
    }
  },
  methods: {
    showPayment() {
      this.$bvModal.show('paymentModal')
    },
    async showDocuments() {
      this.$bvModal.show('bookingDocsModal')
      const docs = await ReservationsService.getDocuments(this.$store.getters["bus/currentResHID"])
      this.bookingDocs = docs.filter((item) => item.type === 'extdoc')
      this.bookingInvoices = docs.filter((item) => item.type === 'invoice')
      this.internalTickets = await ReservationsService.getInternalTickets(this.$store.getters["bus/currentResHID"])
    },
    downloadPDF(doc) {
      const linkSource = `data:application/pdf;base64,${doc.B64_data}`;
      const downloadLink = document.createElement("a");
      const fileName = doc.title;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  },
  mounted() {
    if (this.$props.openPayment) {
      this.showPayment()
    }
  },

}
</script>

<style lang="scss" scoped>


.payed {
  background-color: $lk_green;
}

.notPayed {
  background-color: $lk_red;
}

.overPaid {
  background-color: $lk_orange;
}

.totalAmounttext {
  font-size: 2rem
}

.bg-payments {
  background-color: $lk_grey_1
}

.rounded-left-bottom {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-right-bottom {
  border-bottom-right-radius: 0.25rem !important;
}
</style>
