<template>
  <div v-if="currentShop !== null && currentUser !== null">
    <div class="text-center currentshop"  v-if="mode === 'small'" :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
          <small> {{ currentShop.vestiging }} </small>
    </div>
    <b-dropdown block
        id="changeShop"
        variant="outline-primary"
        class="m-2">
      <template v-slot:button-content>
        <span v-if="mode === 'large'">
          <b-icon icon="house-fill" font-scale="1"></b-icon> {{ currentShop.vestiging }}
        </span>
        <span v-if="mode === 'small'">
          <b-icon icon="house-fill" font-scale="1"></b-icon>
        </span>
      </template>
      <template v-if="currentUser.shops">
        <b-dropdown-item-button
            v-for="shop in currentUser.shops"
            v-bind:key="shop.FKid_vestiging"
            @click="changeShop(shop)"
            :active="currentShop === shop"
        >
        <template v-if="shop.vestiging"> {{ shop.vestiging }} </template>
      </b-dropdown-item-button>
      </template>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        v-for="(action, index) in actions"
        :key="index"
        @click.prevent="action.onclick"
      >
        <b-icon scale="0.75" :icon="action.icon"></b-icon>
        {{ action.label }}
      </b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script>
export default {
  name: "ShopChooser",
  data() {
    return {
      actions: [
        { icon: "lock", label: this.$t("nav.lock"), onclick: this.lock },
        {
          icon: "box-arrow-left",
          label: this.$t("nav.logoff"),
          onclick: this.logOut,
        },
      ],
    };
  },
  props:{
    mode: {
      type: String,
      default: 'large'
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/currentUser"]
    },
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("user/logout");
    },
    lock() {
      this.$store.dispatch("user/lock");
    },
    changeShop(shop) {
      this.$store.dispatch("bus/changeShop", shop)
    }
  }
}

</script>

<style lang="scss" scoped>

.currentshop{
  &.theme-light{
    color: black;
  }
  &.theme-dark{
    color: $lk_grey_3;
  }

}
.btn-outline-secondary {
  min-width: 8em;

  &:hover {
    background: transparent;
    color: $secondary;
  }
}

</style>
