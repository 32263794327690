<template>
  <div id="ReservationHeader" v-if="reservation">
    <b-card class="mb-2 border-0 shadow-sm"
            footer-bg-variant="warning"
    >
        <div class="">
          <b-row>
            <b-col cols="12" lg="7" xl="8">
              <h1><span v-if="reservation.customerdata.customerhid !== 'a64eba55b6bbd07369daf8ae62731a53dcd042d1d023f1e65f07e080a0efac90'">{{reservation.customerdata.title}}</span> {{reservation.customerdata.lastname}}</h1>
              <h3>{{ reservation.internalkey }} - {{reservation.shortcode}}</h3>
              <h6>{{ reservation.dt_start | moment("DD-MM-YYYY HH:mm") }}</h6>
              <h6>{{ reservation.dt_end | moment("DD-MM-YYYY HH:mm") }}</h6>
            </b-col>
            <b-col cols="12" lg="5" xl="4" class="d-lg-flex flex-column justify-content-between">
              <div class="d-flex justify-content-between justify-content-lg-end">
                <div ref="btn-epos-print" class="d-none" @click="printEpos">
                  <a class="btn btn-primary mr-3 mb-1">
                    <b-icon icon="receipt-cutoff"></b-icon>
                  </a>
                  <iframe ref="btn-epos-print-iframe" class="d-none"></iframe>
                </div>
                <div v-if="reservation.customerdata.mobilephonesanatized">
                  <a class="btn btn-primary mr-3 mb-1" :href="'tel:+' + reservation.customerdata.mobilephonesanatized" >
                    <b-icon icon="telephone"></b-icon>
                  </a>
                  <a class="btn btn-success mr-3 mb-1" :href="'https://wa.me/+' + reservation.customerdata.mobilephonesanatized " target="_blank" rel="noopener">
                    <b-icon icon="chat-dots"></b-icon>
                  </a>
                </div>
                <div class="d-none d-md-block" v-if="reservation.shortcode">
                  <vue-qrcode
                  :value="`=H${reservation.hash};*`"
                  :margin="0"
                  :width="80"
                  />
                </div>
              </div>
              <div class="">
                <b-select
                  class="custom-select border mt-1 mb-1"
                  :class="'status_bg_'+reservation.booking_status.status_id"
                  v-on:change="$bvModal.show('confirmModal')"
                  v-model="reservation.booking_status.status_id"
                  :disabled="reservation.booking_status.status_id === 10 || !hasRights"
                >
                  <b-select-option
                    v-for="status in reservationStatus"
                    v-bind:key="status.value"
                    :value="status.value"
                    >{{ status.text | capitalize }}
                  </b-select-option>
                </b-select>
              </div>
              <div class="">
                <b-dropdown
                    variant="primary"
                    :text="$t('resdetails.mailacties')"
                    block
                >
                  <b-dropdown-item-button v-on:click="doAction('sendConformationEmail')">{{ $t('resdetails.verstuurbevestiging') }}</b-dropdown-item-button>
                  <b-dropdown-item-button v-on:click="doAction('sendPaymentLink')">{{ $t('resdetails.verstuurbetalingsemail') }}</b-dropdown-item-button>
                  <b-dropdown-item-button v-on:click="doAction('sendPayedReservationMail')">{{ $t('resdetails.verstuurbetaaldemail') }}</b-dropdown-item-button>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </div>
      <template v-slot:footer v-if="reservation.isBooking">
        <span class="font-italic" v-if="!reservation.isSubReservation">{{ $t('alg.boekinghoofdtekst') }}</span>
        <span v-for="(data, key) in reservation.subReservationData"
              v-bind:key="key"
        >
          {{key}} ({{data.shop.name}})
        </span>
        <span class="font-italic" v-if="reservation.isSubReservation">{{ $t('alg.boekingsubtekst') }} {{ reservation.mainReservationData.internalkey }}</span>
      </template>
    </b-card>

    <b-modal
      id="confirmModal"
      :title="$t('alg.statusaanpassing')"
      :ok-title="$t('alg.ja')"
      :cancel-title="$t('alg.nee')"
      @ok="setStatus()"
      @cancel="cancelStatusChange()"
      @close="cancelStatusChange()"
      no-close-on-backdrop
      no-close-on-esc
    >
      <p class="my-4">{{ $t("status.aanpassen")}}</p>
    </b-modal>

  </div>
</template>

<script>
import ReservationsService from '../services/reservations.service'
import VueQrcode from 'vue-qrcode'

export default {
  props: ['reservation'],
  name: 'ReservationHeader',
  components: {
    VueQrcode,
  },
  data() {
    return {
      reservationStatus: [
        {value: 1, text: this.$t("status.aangevraagd")},
        {value: 2, text: this.$t("status.bezigmetreservering")},
        {value: 3, text: this.$t("status.gereserveerd")},
        {value: 4, text: this.$t("status.actief")},
        {value: 5, text: this.$t("status.wachtopbetaling")},
        {value: 6, text: this.$t("status.betalingscontrole")},
        {value: 7, text: this.$t("status.uitgesteldafgehandeld")},
        {value: 9, text: this.$t("status.afgehandeld")},
        {value: 10, text: this.$t("status.geannuleerd")}
      ],
      chosenAction: 'sendConformationEmail'
    };
  },
  computed: {
    chosenActionText(){
      switch (this.chosenAction){
        case 'sendConformationEmail':
          return this.$t('resdetails.verstuurbevestiging')
        case 'sendPaymentLink':
          return this.$t('resdetails.verstuurbetalingsemail')
        case 'sendPayedReservationMail':
          return this.$t('resdetails.verstuurbetaaldemail')
        default:
          return this.$t('alg.onbekend')
      }
    },
    hasRights() {
      return this.$store.getters['user/hasRight']("reservation_crud");
    }
  },
  methods: {
    doAction(action){
      switch (action){
        case 'sendConformationEmail':
          this.sendReservationMailEmail()
          break;
        case 'sendPaymentLink':
          this.sendPaymentLink()
          break;
        case 'sendPayedReservationMail':
          this.sendPayedReservationMail()
          break;
        default:
          console.log('unknown action')
      }
    },
    saveStatus(){
      this.orgStatus = this.reservation.booking_status.status_id
    },
    async setStatus(){
      let newStatus = this.reservation.booking_status.status_id
        try{
          await ReservationsService.setstatus(this.$store.getters["bus/currentResHID"], newStatus)
          this.saveStatus()
          this.$emit('reservationsOverviewRefresh')
        } catch (err) {
          this.toast('err', err)
        }
    },
    cancelStatusChange(){
      this.reservation.booking_status.status_id = this.orgStatus
    },
    async sendReservationMailEmail(){
      try{
        const d = await ReservationsService.sendReservationMail(this.$store.getters["bus/currentResHID"])
        if (d && d.data && d.data.status) {
          if (d.data.status !== 'OK') {
            this.toast('notice', d.data.data)
          } else {
            this.toast('success', 'Email queued', 'Success')
          }
        }else{
          this.toast('err', 'Invalid Api response')
        }
      } catch (err) {
        this.toast('err', err)
      }
    },
    async sendPaymentLink(){
      try{
        const d = await ReservationsService.sendPaymentLinkMail(this.$store.getters["bus/currentResHID"])
        if (d && d.data && d.data.status) {
          if (d.data.status !== 'OK') {
            this.toast('notice', d.data.data)
          } else {
            this.toast('success', 'Email queued', 'Success')
          }
        }else{
          this.toast('err', 'Invalid Api response')
        }
      } catch (err) {
        this.toast('err', err)
      }
    },
    async sendPayedReservationMail(){
      try{
        const d = await ReservationsService.sendPayedReservationMail(this.$store.getters["bus/currentResHID"])
        if (d && d.data && d.data.status) {
          if (d.data.status !== 'OK') {
            this.toast('notice', d.data.data)
          } else {
            this.toast('success', 'Email queued', 'Success')
          }
        }else{
          this.toast('err', 'Invalid Api response')
        }
      } catch (err) {
        this.toast('err', err)
      }
    },
    initAddons(){
      if(
          this.$store.getters["bus/currentShop"] &&
          this.$store.getters["bus/currentShop"].addons &&
          this.$store.getters["bus/currentShop"].addons.pageIncludes
      ) {
        for (const entry in this.$store.getters["bus/currentShop"].addons.pageIncludes) {
          const addon = this.$store.getters["bus/currentShop"].addons.pageIncludes[entry]
          if(addon.pageIncludes.fd){
            for (const el in addon.pageIncludes.fd){
              if(
                  addon.pageIncludes.fd[el].targetPage &&
                  addon.pageIncludes.fd[el].targetPage === "ReservationHeader" &&
                  addon.pageIncludes.fd[el].targetElementId &&
                  this.$refs[addon.pageIncludes.fd[el].targetElementId]
              )
              {
                this.$refs[addon.pageIncludes.fd[el].targetElementId].classList.remove('d-none')
              }
            }
          }
        }
      }
    },
    printEpos(){
      if(
          this.$store.getters["bus/currentShop"] &&
          this.$store.getters["bus/currentShop"].addons &&
          this.$store.getters["bus/currentShop"].addons.pageIncludes
      ) {
        for (const entry in this.$store.getters["bus/currentShop"].addons.pageIncludes) {
          const addon = this.$store.getters["bus/currentShop"].addons.pageIncludes[entry]
          if(addon.pageIncludes.fd){
            for (const el in addon.pageIncludes.fd){
              if(
                  addon.pageIncludes.fd[el].targetPage &&
                  addon.pageIncludes.fd[el].targetPage === "ReservationHeader" &&
                  addon.pageIncludes.fd[el].targetElementId &&
                  this.$refs[addon.pageIncludes.fd[el].targetElementId]
              )
              {
                let uri = addon.pageIncludes.fd[el].uri;
                uri += `&s=${this.$store.getters["bus/currentShop"].hashcode}`
                uri += `&r=${this.reservation.hash}`
                uri += `&jwt=${this.$store.getters["user/jwt"]}`
                this.$refs[`${addon.pageIncludes.fd[el].targetElementId}-iframe`].src=`${uri}`
              }
            }
          }
        }
      }
    }
  },
  directives: {

  },
  watch: {

  },
  created() {
    this.saveStatus()
    this.unwatchShop = this.$store.watch(
        (state, getters) => getters["bus/currentShop"],
        () => {
          this.initAddons()
        },
    );
  },
  beforeMount() {
  },
  mounted() {
    this.initAddons()
  },
  beforeDestroy() {
  },
}
</script>

<style lang="scss" scoped>

h1, h2, h3, h4, h5{
  font-weight: bold;
  color: $dark;
}
h6{
  font-family: $font-family-base !important;
  color: $dark;
}
.status_bg_1, .status_bg_2, .status_bg_5, .status_bg_6{
  border-color: $lk_yellow !important;
}
.status_bg_3{
  border-color: $lk_green !important;
}
.status_bg_4{
  border-color: $lk_blue !important;
}
.status_bg_10{
  border-color: $lk_red !important;
}

</style>
