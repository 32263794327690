<template>
  <div>
    <div v-if="material !== null">
      <b-row class="mb-2">
        <b-col cols="12" class="d-flex justify-content-between">
          <h4>{{ material.label }}</h4>
          <div class="ml-1 h-100 align-self-center">
            <b-form-checkbox v-if="allowedToEdit" switch v-model="editEnabled">
              {{ $t("alg.bewerken") }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="d-flex justify-content-between">
            <template v-if="hasRes">
              <b-button class="align-self-baseline"
                        @click="removeMaterial"
              >
                <b-iconstack font-scale="1">
                  <b-icon stacked icon="link" variant="light" scale="1"></b-icon>
                  <b-icon stacked icon="slash-circle" variant="danger"></b-icon>
                </b-iconstack>
                {{ $t("materiaal.loskoppelen") }}
              </b-button>
              <b-button class="align-self-baseline"
                        @click="openRes(material.resdata.idreservation)"
              >
                <b-iconstack font-scale="1" class="align-self-baseline">
                  <b-icon stacked icon="link" variant="light" scale="1"></b-icon>
                </b-iconstack>
                {{ $t("materiaal.aan") }} {{ material.resdata.idreservation }}
              </b-button>
            </template>
            <template v-if="!hasRes">
              <b-button-group>
                <button
                    v-for="status in [{name: 'active', color: 'success'}, {name: 'broken',color:'danger'}, {name: 'outOfUse',color:'secondary'}]"
                    :key="'status-btn-'+status.name"
                    class="btn"
                    :disabled="!editEnabled"
                    :class="material.matstatus === status.name ? 'btn-'+status.color : 'btn-outline-'+status.color"
                    @click="() => updateStatus(status.name)">
                  {{ $t("materiaal.status." + status.name) }}
                </button>
              </b-button-group>
              <b-button variant="outline-warning"
                        :disabled="!editEnabled"
                        v-b-modal:modal-maintenance>
                {{ $t("materiaal.voegonderhoudtoe") }}
              </b-button>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row class="border-top border-light">
        <b-col cols="4" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.label") }}</span>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          {{ material.label }}
        </b-col>
      </b-row>
      <b-row class="border-bottom border-light mb-1">
        <b-col cols="4" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.soortverhuuritem") }}</span>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          <span>{{ material.vhi.omschrijving }}</span> <span class="small"> ({{
            material.vhi.id_verhuur_items_vestiging
          }}) </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.eigenaar") }}</span>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          {{ material.creator.desc }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.huidigevestiging") }}</span>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          {{ material.vhi.current_shop }}
        </b-col>
      </b-row>
      <b-row class="border-bottom border-light mb-1">
        <b-col cols="4" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("status.status") }}</span>
        </b-col>
        <b-col cols="8" md="9" lg="10">
          <b-row>
            <b-col cols="12" sm="6" md="3">
              <b-form-checkbox switch class="mr-n2" v-model="material.outofuse"
                               disabled="disabled"
              >
                {{ $t("materiaal.buitengebruik") }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" sm="6" md="3">
              <b-form-checkbox switch class="mr-n2" v-model="material.disabled"
                               disabled="disabled"
              >
                {{ $t("materiaal.zichtbaarheid") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.notities") }}</span>
        </b-col>
        <b-col cols="12" md="9" lg="10">
          <b-form-textarea class="border border-primary"
                           rows="4"
                           no-resize
                           v-model="material.note"
                           :disabled="!editEnabled"
                           @change="saveNote"
          >

          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="mt-1 border-bottom border-light mb-1">
        <b-col cols="12" md="3" lg="2">
          <span class="font-weight-bold">{{ $t("alg.kenmerken") }}</span>
        </b-col>
        <b-col cols="12" md="9" lg="10">
          <b-row>
            <b-col cols="4" md="3" lg="2" class="bold text-secondary">{{ $t("alg.kenmerk") }}</b-col>
            <b-col cols="8" md="9" lg="10" class="bold text-secondary">{{ $t("alg.omschrijving") }}</b-col>
          </b-row>
          <div class="minheigth p-1 mb-1" v-if="(material.kenmerken) !== []">
            <b-row
                v-for="(value, key) in material.kenmerken"
                v-bind:key="key"
            >
              <b-col cols="4" md="3" lg="2">{{ key }}</b-col>
              <b-col cols="8" md="9" lg="10">{{ value }}</b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col cols="12">
          <span class="font-weight-bold">{{ $t("alg.geschiedenis") }}</span>
        </b-col>
        <b-col cols="12">
          <b-table
              sticky-header="true"
              stacked="sm"
              striped
              small
              class="mb-0 border-bottom border-grey"
              :items="material.history"
              :fields="tablelables"
          >
          </b-table>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="modal-maintenance"
        ref="modal"
        :title="$t('materiaal.onderhoud.titel')"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleMaintenanceSubmit">
        <h5>{{ $t("materiaal.onderhoud.defect") }}</h5>
        <b-form-input
            id="name-input"
            v-model="maintenanceNote"
            autofocus
        ></b-form-input>
        <h5 class="my-3 text-center">- {{ $t("alg.of") }} -</h5>
        <h5>{{ $t("materiaal.onderhoud.kiespreset") }}</h5>
        <div class="d-flex flex-wrap mt-2">
          <b-button v-for="preset of maintenancePresets" @click="() => addRepair(preset)" class="m-1" :key="preset">{{ preset }}</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
        id="modal-last-material"
        ref="modal-last-material"
        :title="$t('materiaal.laatsteMateriaal.title')"
        @ok="finishReservation"
    >
      {{ $t('materiaal.laatsteMateriaal.tekst') }}
    </b-modal>
  </div>
</template>
<script>
import ScanAndGoService from '../services/scanandgo.service';
import ReservationsService from "@/services/reservations.service";

export default {
  name: 'MaterialDetails',
  props: {
    materialId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      material: null,
      scannedResid: null,
      editEnabled: false,
      isDirty: false,
      maintenanceNote: '',
      maintenancePresets: [],
      tablelables: [
        {
          key: 'timestamp',
          label: this.$t("alg.datum"),
          sortable: true
        }, {
          key: 'history',
          label: this.$t("alg.omschrijving"),
          sortable: false
        }]
    }
  },
  computed: {
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    hasRes() {
      return this.material.resdata && this.material.resdata.idreservation
    },
    allowedToEdit() {
      return this.$store.getters["user/hasRight"]("verhuuritems_crud")
    }
  },
  methods: {
    async getDetails() {
      if (this.materialId !== null && this.currentShop) {
        try {
          this.material = await ScanAndGoService.getMaterial(this.currentShop['hashcode'], this.materialId)
        } catch (err) {
          this.toast('err', err)
        }
      }
    },
    openRes(idres) {
      this.$emit('navigateToRes')
      setTimeout(() => {
        this.$store.dispatch('bus/changeRes', idres)
      }, 300)
    },
    async updateStatus(status) {
      await ScanAndGoService.updateStatus(this.currentShop["hashcode"], this.$props.materialId, status);
      this.material.matstatus = status;
    },
    async saveNote() {
      try {
        this.material = await ScanAndGoService.changeNote(this.currentShop['hashcode'], this.$props.materialId, this.material.note)
      } catch (err) {
        this.toast('err', err)
      }
    },
    async removeMaterial() {
      const resid = this.material.resdata.idreservation;
      const id = this.$props.materialId
      const result = await ScanAndGoService.unsetFromReservation(this.currentShop['hashcode'], id)
      if (result.code === 2065) {
        this.toast('success', this.$t("materiaal.losgekoppeld.tekst"), this.$t("materiaal.losgekoppeld.title") + '!');
        // Check if all material for reservation has been scanned
        const materialsList = await ScanAndGoService.listByReservation(this.currentShop['hashcode'], resid)
        if (!materialsList || materialsList.length <= 0) {
          this.scannedResid = resid;
          this.$bvModal.show('modal-last-material');
        }

      } else {
        this.toast('err', result.error)
      }
      this.$emit('reservationsOverviewRefresh')
      await this.getDetails()
    },
    async finishReservation() {
      if (this.scannedResid)
          // Set reservation to status 'afgehandeld'{
        try {
          await ReservationsService.setstatus(this.scannedResid, 9);
          this.toast('success', this.$t("materiaal.laatsteMateriaal.success"), this.$t("alg.opgeslagen"));
          this.$router.push({name: "ReservationsOverview"});
        } catch (e) {
          this.toast('error', this.$t("materiaal.laatsteMateriaal.error"), this.$t("alg.fout"));
        }
    },
    resetModal() {
      this.maintenanceNote = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleMaintenanceSubmit()
    },
    async handleMaintenanceSubmit() {
      await ScanAndGoService.addRepair(this.currentShop["hashcode"], this.materialId, this.maintenanceNote);
      await this.getDetails();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-maintenance')
      })
    },
    async addRepair(description) {
      this.maintenanceNote = description;
      await this.handleMaintenanceSubmit();
    },
    async getMaintenancePresets() {
      if (this.currentShop)
        this.maintenancePresets = await ScanAndGoService.getRepairPresets(this.currentShop["hashcode"]);
    }
  },
  watch: {
    materialId() {
      this.getDetails();
    },
  },
  mounted() {
    if (this.currentShop) {
      this.getDetails();
      this.getMaintenancePresets();
    } else {
      const unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "bus/changeShop") {
          this.getDetails();
          this.getMaintenancePresets();
          unsubscribe();
        }
      })
    }
  }
}
</script>
<style lang="css" scoped>
.minheigth {
  min-height: 30px;
}
</style>
