<template>
  <b-modal
      :visible="isVisible"
      class="scanModal"
      hide-footer
      @hide="closeScan()"
      :return-focus="$props.returnFocus"
  >
    <template #modal-header=" {close} ">
      <div class="container-fluid">
        <b-row>
          <b-col cols="6">
            <h3>{{$t('scanner.scanner')}}</h3>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button size="sm" variant="outline-warning" @click="close()">
              {{$t('alg.sluiten')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <b-alert show v-if="currentResHID() !== null">
              <b-icon icon="exclamation-diamond-fill"></b-icon>
                {{$t('materiaal.koppelmateriaal')}}
            </b-alert>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default>
      <QRScanner :current-reshid="currentResHID()" :done-function="closeScan"></QRScanner>
    </template>
  </b-modal>
</template>

<script>
import QRScanner from "@/components/QRScanner"

export default {
  name: "QRScannerModal",
  props: {
    returnFocus: {
      type: String,
      default: ''
    },
    returnFunction:{
      type: Function,
      default: null
    }
  },
  components: {
    QRScanner
  },
  computed: {
    isVisible() {
      return this.$store.getters["bus/showScan"]
    },
  },
  methods: {
    closeScan() {
      if(typeof this.$props.returnFunction ===  'function'){
        this.$props.returnFunction()
      }
      this.$store.dispatch("bus/closeScan")
    },
    currentResHID () {
      return this.$store.getters['bus/currentResHID']
    }
  },
}
</script>

<style scoped>

.scanModal .modal-body {
  height: 87vh;
}
</style>
