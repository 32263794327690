<template>
  <b-row id="header" class="fixed-top no-gutters w-100" :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
    <b-col cols="2" class="pl-2 my-auto">
      <img id="header-logo"
           :src="$store.getters['misc/getDarkMode'] ? require('@/assets/images/leisure-king-logo-klein-licht.png') : require('@/assets/images/leisure-king-logo-klein-donker.png')"
      >
    </b-col>
    <b-col cols="10" class="my-auto d-flex justify-content-end">
      <ShopChooser></ShopChooser>
    </b-col>
  </b-row>
</template>

<script>
import ShopChooser from "@/components/ShopChooser";

export default {
  name: 'Header',
  components: {ShopChooser},
  data() {
    return {
    };
  },
}
</script>

<style lang="scss">
#header {
  height: 60px;

  &.theme-light{
    background-color: $white;
  }
  &.theme-dark{
    background-color: $lk_grey_12;
  }

  #header-logo {
    margin: 5px;
    height: 50px;
  }

}
//@media only screen and (min-width: 768px) {
@include media-breakpoint-up(md) {
  #header{
    height: 90px;

    #header-logo {
      height: 80px;
    }
  }
}
</style>
