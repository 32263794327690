<template>
  <div id="navtop" class="d-flex flex-column justify-content-between w-100" :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
    <div class="d-md-block d-sm-none d-none">
      <img
        id="navlogo"
        :src="$store.getters['misc/getDarkMode'] ? require('@/assets/images/leisure-king-logo-klein-licht.png') : require('@/assets/images/leisure-king-logo-klein-donker.png')"
      />
    </div>

    <b-nav toggleable="false" justified :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
      <b-nav-item
        active-class="active"
        v-bind:key="link.meta.ord"
        v-for="link in mainItems"
        :to="link.path"
        @click="isMoreActive = false"
        class="py-3"
      >
        <div class="d-flex flex-column align-items-center">
          <b-icon :icon="link.meta.icon"></b-icon>
          <span v-if="!link.meta.captionText">
            {{ $t(`alg.${link.meta.caption}`) }}
          </span>
          <span v-if="link.meta.captionText">{{ link.meta.captionText }}</span>
        </div>
      </b-nav-item>
      <template v-if="extraItems.length > 0">
        <b-nav-item-dropdown
          no-caret
          :dropright="!!(this.$screen.lg || this.$screen.md)"
          :dropup="!this.$screen.lg && !this.$screen.md"
        >
          <template v-slot:button-content>
            <div
              class="d-flex flex-column align-items-center"
              :class="isMoreActive ? 'text-primary' : 'text-gray'"
            >
              <b-icon scale="1.5" icon="list"></b-icon>
              <span>{{ $t(`alg.meer`) }}</span>
            </div>
          </template>
          <b-dropdown-item
            variant="transparant"
            v-for="link in this.extraItems"
            v-bind:key="link.meta.ord"
            :to="link.path"
            @click="isMoreActive = true"
          >
            <div
              class="d-flex flex-row align-items-center justify-content-start"
            >
              <b-icon class="mr-2" :icon="link.meta.icon"></b-icon>
              <span v-if="!link.meta.captionText">
                {{ $t(`alg.${link.meta.caption}`) }}
              </span>
              <span v-if="link.meta.captionText">
                {{ link.meta.captionText }}
              </span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
              variant="transparant"
              to="version"
              @click="isMoreActive = true"
          >
            <div
                class="d-flex flex-row align-items-center justify-content-end"
            >
              <small class="text-muted">{{ appVersion }}</small>
              <div
                  class="ml-2 text-warning"
                  v-if="latestVersion !== appVersion"
                  v-b-tooltip.hover
                  :title="`${$t('alg.versionupdate')} ${$t('alg.clickForInfo')}`"
              >
                <b-icon-exclamation-diamond-fill></b-icon-exclamation-diamond-fill>
              </div>

            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </template>
    </b-nav>

    <div class="d-md-block d-sm-none d-none w-100 text-center">
      <ShopChooser mode="small"></ShopChooser>
    </div>
    <div class="d-md-block d-sm-none d-none">
      <router-link v-bind:to="'version'">
        <div class="w-100 d-flex justify-content-center">
          <div>
            <small class="text-muted">{{ appVersion }}</small>
          </div>
          <div
            class="ml-2 text-warning"
            v-if="latestVersion !== appVersion"
            v-b-tooltip.hover
            :title="`${$t('alg.versionupdate')} ${$t('alg.clickForInfo')}`"
          >
            <b-icon-exclamation-diamond-fill></b-icon-exclamation-diamond-fill>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import ShopChooser from "@/components/ShopChooser";

export default {
  name: "Nav",
  components: { ShopChooser },
  data() {
    return {
      appVersion: this.$appVersion,
      isMoreActive: false,
      mainItems: [],
      extraItems: [],
    };
  },
  methods: {
    isResized() {
      this.$nextTick(() => {
        this.init();
      });
    },
    init() {
      this.mainItems = [];
      this.extraItems = [];
      let links = this.$router.options.routes.filter((item) => {
        if (item.meta && item.meta.isMainMenu) {
          return item;
        } else if (item.meta && item.meta.isMenuItem) {
          if (item.meta.requiresOption) {
            if (
              this.$store.getters["bus/currentShop"] &&
              this.$store.getters["bus/currentShop"].options &&
              this.$store.getters["bus/currentShop"].options[
                item.meta.requiresOption
              ] !== undefined &&
              this.$store.getters["bus/currentShop"].options[
                item.meta.requiresOption
              ] === true
            ) {
              return item;
            }
          }

          if (
            item.meta.requiresAddon &&
            this.$store.getters["bus/currentShop"] &&
            this.$store.getters["bus/currentShop"].addons &&
            this.$store.getters["bus/currentShop"].addons.menuItems
          ) {
            for (const entry in this.$store.getters["bus/currentShop"].addons.menuItems) {
              if (
                this.$store.getters["bus/currentShop"].addons.menuItems[entry]
                  .addOnId === item.meta.requiresAddon
              ) {
                item.meta.captionText =
                  this.$store.getters["bus/currentShop"].addons.menuItems[
                    entry
                  ].menuItems.fd[0].caption;
                return item;
              }
            }
          } else {
            return item;
          }
        }
      });

      // Filter links based on user_rights
      links = links.filter((link) => {
        let hasRights = true;
        if (link.meta.requiredRights && link.meta.requiredRights.length > 0) {
          link.meta.requiredRights.forEach((right) => {
            if (!this.$store.getters["user/hasRight"](right)) {
              hasRights = false;
              return;
            }
          });
        }
        return hasRights; //? item : null;
      });

      links = links.map((item) => {
        item.path = "/" + item.path.split("/")[1];
        return item;
      });
      links.sort((a, b) => {
        return a.meta.ord - b.meta.ord;
      });
      //screen test
      if (this.$screen.lg || this.$screen.md) {
        //side menu
        if (links.length > 5) {
          this.mainItems = links.slice(0, 6);
          this.extraItems = links.slice(6, links.length);
        } else {
          this.mainItems = links;
        }
      } else {
        //bottom menu
        this.mainItems = links.slice(0, 2);
        this.extraItems = links.slice(2, links.length);
      }
    },
  },
  computed: {
    latestVersion() {
      return this.$store.getters["user/currentUser"]
        ? this.$store.getters["user/currentUser"].fdAppVersion
        : "0.0.0";
    },
  },
  created() {
    this.init();
    window.addEventListener("resize", this.isResized);
    this.unwatchShop = this.$store.watch(
      (state, getters) => getters["bus/currentShop"],
      () => {
        this.init();
      }
    );
  },
  beforeDestroy() {
    this.unwatchShop();
    window.removeEventListener("resize", this.isResized);
  },
};
</script>

<style scoped lang="scss">
#navtop{
  &.theme-light{
    background-color: $white;
  }
  &.theme-dark{
    background-color: $lk_grey_12;
  }
}
.nav {
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  height: 60px;
  bottom: 0px;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;

  .nav-item {
    text-align: center;
  }

  .nav-link:hover {
    color: #2499a9 !important;
  }

  .active {
    color: #2499a9 !important;
  }

}
.theme-light{
  .nav{
    background-color: white;
  }
  .nav-link{
    color: grey !important;
  }
}
.theme-dark{
  .nav{
    background-color: $lk_grey_12;
  }
  .nav-link{
    color: $lk_grey_3 !important;
  }
}



/*@media only screen and (min-width: 769px) {*/

@include media-breakpoint-up(md) {
  .nav {
    position: relative;
    width: 90px;
    left: 0px;
    height: 100%;

    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
  }

  .nav-item {
    height: 90px;
    width: 100%;
  }

  #navlogo {
    width: 60px;
    margin: 15px;
  }
}
</style>
