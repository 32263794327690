<template>
  <div v-if="$props.reservation">

    <b-modal
        id="modal-pin"
        ref="modal-pin"
        :title="$t('betalingen.pininvoeren')"
        @ok="handleModalPin"
    >
      <form ref="form" @submit.stop.prevent="handleModalPin" class="mb-2">
        <b-form-group
            id="fieldset-1"
            description=""
            label=""
            label-for="pin-input"
            :invalid-feedback="$t('validationmessages.minlength', ['4'])"
            :state="pinInputValid"
        >
          <b-form-input
              type="password"
              ref="modal-pin-input"
              id="modal-pin-input"
              v-model="pininput"
              :state="pinInputValid"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <div v-if="!EFTActive">
      <div
          class="text-white text-center py-3"
          :class="[
          { 'payed' : $props.reservation.value.amount === $props.reservation.payed.amount },
          { 'notPayed' : $props.reservation.value.amount > $props.reservation.payed.amount },
          { 'overPaid' : $props.reservation.value.amount < $props.reservation.payed.amount }
        ]"
      >
        <div class="text-white totalAmounttext">
          <span v-html="$props.reservation.value.currency_sign"></span>
          {{ $props.reservation.value.amount | money($props.reservation.value.currency_id) }}
        </div>
        <div class="d-flex justify-content-center">
          <div id="payed">{{ $t('betalingen.betaald') }}: <span v-html="$props.reservation.payed.currency_sign"></span>
            {{ $props.reservation.payed.amount | money($props.reservation.value.currency_id) }}
          </div>
          <span class="px-2"> - </span>
          <div id="amount">{{ $t('betalingen.restant') }}: <span v-html="$props.reservation.value.currency_sign"></span>
            {{
              ($props.reservation.value.amount - $props.reservation.payed.amount) | money($props.reservation.value.currency_id)
            }}
          </div>
        </div>
      </div>
      <div v-if="isBorgRefund" class="py-3 bg-warning">
        <h3 class="text-white text-center">{{$t('resdetails.borg')}} {{$t('betalingen.terugbetalen')}}</h3>
      </div>
      <div class="px-3 py-2">
        <b-row>
          <b-col>
            <p>{{ $t('betalingen.bedrag') }}</p>
            <b-input-group prepend="€" v-if="$props.reservation">
              <currency-input
                  v-model="transferAmount"
                  @click="amountEditable ? transferAmount = 0 : transferAmount"
                  :readonly="!amountEditable"
                  class="form-control"
              />
            </b-input-group>
          </b-col>
          <b-col>
            <p>{{ $t('betalingen.betaalmethode') }}</p>
            <b-form-select v-if="selectedPaymentMethod" class="mb-3" v-model="selectedPaymentMethod">
              <b-form-select-option
                  v-for="method in paymentMethodsAllowed"
                  v-bind:key="method.id_payment_method"
                  :value="method.id_payment_method"
              >{{ method.description }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <div class="text-center d-flex justify-content-center my-3">
          <b-button variant="success" v-on:click="setPayment()" :disabled="transferAmount === 0">
            {{ $t('betalingen.betalingbevestigen') }}
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="EFTActive">
      <div
          class="text-white text-center py-3"
          :class="EFTStatusClass"
      >
        <div class="text-white totalAmounttext">
          <span v-html="$props.reservation.value.currency_sign"></span>
          {{ transferAmount | money($props.reservation.value.currency_id) }}
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <span>{{ EFTTransactionId }}</span>
          </div>
        </div>
        <div v-if="transferAmount < 0" class="d-flex justify-content-center">
          <div>
            <h3 class="font-weight-bold text-white">Retourpinnen - Steek kaart in kaartlezer</h3>
          </div>
        </div>
      </div>
      <b-progress variant="warning" :value="EFTProgress" max="45" striped animated></b-progress>
      <div class="px-3 py-2">
        <b-row>
          <b-col cols="12" class="text-center">
            <h3>{{ EFTStatusText }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-button variant="danger" @click="cancelEFT()">Annuleren</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="bg-payments rounded-bottom">
      <div class="px-3 py-2">
        <div class="text-center border-bottom border-dark mb-3 pb-2">
          <span class="font-weight-bold"> {{ $t('betalingen.betalingen') }} </span>
        </div>
        <b-row class="mb-3 no-gutters text-center text-muted">
          <b-col>
            {{ $t('alg.datum') }}
          </b-col>
          <b-col>
            {{ $t('betalingen.bedrag') }}
          </b-col>
          <b-col>
            {{ $t('betalingen.betaalmethode') }}
          </b-col>
        </b-row>
        <template v-if="!payments">
          <b-row>
            <b-col class="text-center">{{ $t('betalingen.geenbetalingen') }}!</b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row
              class="mb-3 no-gutters text-center"
              v-for="payment in payments"
              v-bind:key="payment.id_payment"
          >
            <b-col>
              {{ payment.dt_payment | moment('DD-MM-YYYY HH:mm') }}
            </b-col>
            <b-col>
              € {{ payment.amount | money($props.reservation.value.currency_id) }}
            </b-col>
            <b-col>
              {{ payment.paymentMethod }}
              <template v-if="payment.pinReceiptUrl !== null">
                <b-icon :id="`pp_${payment.id_payment}`" icon="receipt"></b-icon>
                <b-popover :target="`pp_${payment.id_payment}`" custom-class="pinpopover" triggers="hover">
                  <b-img-lazy :src="payment.pinReceiptUrl">
                  </b-img-lazy>
                  <b-button block @click="printEFTReceipt(payment.pinReceiptUrl)">Print</b-button>
                </b-popover>
              </template>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationsService from '../services/reservations.service';
import ShopsService from '../services/shops.service';

export default {
  props: ['reservation'],
  name: 'Payments',
  data() {
    return {
      transferAmount: 0,
      amountEditable: true,
      borgAmount: 0,
      paymentMethods: null,
      paymentMethodsAllowed: null,
      selectedPaymentMethod: null,
      payments: null,
      possibleEFTs: [],
      EFTActive: false,
      EFTTransactionId: null,
      EFTStatusHash: null,
      EFTStatusClass: 'bg-primary',
      EFTStatusText: 'Start de betaling ...',
      EFTProgress: 0,
      pininput: null,
    };
  },
  methods: {
    async getPayments() {
      this.payments = null
      try {
        this.payments = await ReservationsService.getpayments(this.$props.reservation.shophid, this.$props.reservation.hash)
      } catch (err) {
        this.toast('err', err)
      }
    },
    async refreshPayments() {

      try {
        this.$emit('reservationsOverviewRefresh')
        this.payments = await ReservationsService.getpayments(this.$props.reservation.shophid, this.$props.reservation.hash)
        let total = 0
        this.payments.forEach((payment)=>{
          total = total + payment.amount
        })
        if(this.$props.reservation.value.amount === total){
          this.$bvModal.hide('paymentModal')
        }
      } catch (err) {
        this.toast('err', err)
      }
    },
    async getPaymentMethods() {
      try {
        this.paymentMethods = await ShopsService.getPaymentMethods(this.$props.reservation.shophid)
        for (const pm in this.paymentMethods) {
          if (this.paymentMethods[pm].id_payment_method === 2) {
            this.possibleEFTs = this.paymentMethods[pm].terminals
          }
        }
        this.fillPaymentMethods()
      } catch (err) {
        this.toast('err', err)
      }
    },
    fillPaymentMethods(){
      this.paymentMethodsAllowed = JSON.parse(JSON.stringify(this.paymentMethods))
      this.selectedPaymentMethod = this.paymentMethodsAllowed[0].id_payment_method
    },
    async setPayment() {
      const defaultEFT = JSON.parse(localStorage.getItem('lk.defaultEFT')) || null
      if (this.selectedPaymentMethod === 2 && this.possibleEFTs.length > 0) {
        if (defaultEFT !== null && this.possibleEFTs.filter((item) => item.id_terminal === defaultEFT.id_terminal).length === 1) {
          const result = await ReservationsService.setPayment(this.$props.reservation.shophid, this.$props.reservation.hash, this.transferAmount, this.selectedPaymentMethod, defaultEFT.id_terminal)
          if (result.status === 200 && result.data.status === "OK") {
            this.EFTActive = true
            this.EFTTransactionId = result.data.data.transactionId
            this.EFTStatusHash = result.data.data.statusHash
            this.startEFTCounter()
            await this.pollEFTStatus()
            return
          } else {
            this.toast('err', result.data.data, 'Terminaltransaction not started!')
            return
          }
        } else {
          if (defaultEFT && defaultEFT.id_terminal !== 'none') {
            this.toast('notice', 'Select valid default EFT first in settings!')
            return
          }
        }
      }

      try {
        const result = await ReservationsService.setPayment(this.$props.reservation.shophid, this.$props.reservation.hash, this.transferAmount, this.selectedPaymentMethod, null)
        if (result.status === 200 && result.data.status === "OK") {
          await this.refreshPayments()
        } else {
          this.toast('err', result.data)
        }
      } catch (err) {
        this.toast('err', err)
      }

    },
    pollEFTStatus() {
      ReservationsService.getPaymentStatus(this.$props.reservation.shophid, this.EFTStatusHash, this.$props.reservation.hash)
          .then((transactionstatus) => {
            if (transactionstatus.data.status !== 'final') {
              this.EFTStatusText = 'Betaal via betaalautomaat'
              this.pollEFTStatus()
            } else if (transactionstatus.data.cancelled === "1") {
              this.EFTStatusClass = 'bg-warning'
              this.EFTStatusText = 'Betaling geannuleerd'
              this.resetEFTStatus()
            } else if (transactionstatus.data.approved === "1") {
              this.EFTStatusClass = 'bg-success'
              this.EFTStatusText = 'Betaling geslaagd, even geduld a.u.b.'
              this.resetEFTStatus()
              this.transferAmount = 0
              this.amountEditable = true
              setTimeout(() => {
                this.refreshPayments()
              }, 1500)
            } else {
              this.resetEFTStatus()
            }
          })
          .catch((err) => {
            this.toast('err', err)
            this.resetEFTStatus()
          })
    },
    async cancelEFT() {
      await ReservationsService.cancelPayment(this.$props.reservation.shophid, this.EFTStatusHash);
    },
    startEFTCounter() {
      this.interval = setInterval(() => {
        this.EFTProgress++
      }, 1000)

    },
    resetEFTStatus() {
      setTimeout(() => {
        this.EFTActive = false
        this.EFTTransactionId = null
        this.EFTStatusClass = 'bg-primary'
        this.EFTStatusText = 'Start de betaling ...'
        if (this.interval) {
          clearInterval(this.interval)
        }
        this.EFTProgress = 0
      }, 1000)
    },
    printEFTReceipt(imgsrc) {
      const win = window.open('about:blank', "_new");
      win.document.open();
      win.document.write([
        '<html>',
        '   <head>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()">',
        '       <img src="' + imgsrc + '"/>',
        '   </body>',
        '</html>'
      ].join(''));
      win.document.close();
    },
    async handleModalPin() {
      const currentshop = this.$store.getters["bus/currentShop"]
      if (!this.pinInputValid) return
      const d = await ShopsService.checkPin(currentshop.hashcode, this.pininput)
      if (d && d.data && d.data.status) {
        if (d.data.status !== 'OK') {
          this.toast('notice', `Invalid Pin`)
        } else {
          this.amountEditable = false
          this.paymentMethodsAllowed = this.paymentMethods.filter(item => item.id_payment_method === 2)
          this.selectedPaymentMethod = this.paymentMethodsAllowed[0].id_payment_method
        }
      }
      this.$bvModal.hide('modal-pin')
    }
  },
  computed: {
    pinInputValid() {
      return this.pininput !== null && this.pininput.length === 4
    },
    isBorgRefund() {
      return this.borgAmount > 0
    }
  },
  filters: {},
  async mounted() {
    this.borgAmount = this.$store.getters["bus/borgRefund"]
    this.$store.dispatch("bus/unsetBorgRefund")
    await this.getPayments()
    await this.getPaymentMethods()

    if (this.isBorgRefund) {
      this.transferAmount = 0 - this.borgAmount
      this.$nextTick(() => {
        this.$bvModal.show('modal-pin')
      })
    } else {
      this.transferAmount = (this.$props.reservation.value.amount - this.$props.reservation.payed.amount)
      if(this.transferAmount < 0 ){
          this.paymentMethodsAllowed = this.paymentMethods.filter(item => item.id_payment_method === 18)
          this.selectedPaymentMethod = this.paymentMethodsAllowed[0].id_payment_method
      }
    }
    const self = this
    this.$root.$on('bv::modal::shown', function (e) {
      const ref = `${e.componentId}-input`
      this.$nextTick(() => {
        setTimeout(()=>{
          const i = self.$refs[ref]
          if (i) i.focus()
        },300)
      })
    })
  }

}
</script>

<style lang="scss" scoped>

.progress {
  border-radius: 0;
}

.payed {
  background-color: $lk_green;
}

.notPayed {
  background-color: $lk_red;
}

.overPaid {
  background-color: $lk_orange;
}

.totalAmounttext {
  font-size: 2rem
}

.bg-payments {
  background-color: $lk_grey_1
}

.pinpopover {
  max-width: 325px;
  width: 325px;
  padding: 5px;

  img {
    width: 300px;
  }
}
</style>
