<template>
  <div id="ReservationNotes">
    <b-card header-tag="header" header-class="pl-3" class="mb-2 border-0 shadow-sm" no-body>
      <template #header>
        <div class="d-flex justify-content-start">
          <div>
          <span class="spantoggle" v-b-toggle:collapsenotes>
              <b-icon icon="chevron-up" class="when-open"></b-icon>
              <b-icon icon="chevron-down" class="when-closed"></b-icon>
              <span>
                {{ $t('opmerkingen.opmerkingen') }}
              </span>
          </span>
          </div>
        </div>
        <span v-if="reservation.booking_remark" class="resopmerking" v-html="reservation.booking_remark"></span>
      </template>
      <b-collapse id="collapsenotes">
        <b-row class="no-gutters">
          <b-col cols="12" xl="8">
            <b-table
                sticky-header="true"
                stacked="sm"
                striped
                small
                class="mb-0 border-bottom border-grey"
                :items="notes"
                :fields="fields"
            >
              <template #cell(opmerking)="data">
                <template v-if="data.item.linkto">
                  <router-link :to="{ path: `/reservations/${data.item.linkto}`}">{{data.value}}</router-link>
                </template>
                <template v-else>
                  {{data.value}}
                </template>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" xl="4" class="py-2 px-3">
            <p>{{ $t('opmerkingen.nieuweopmerking') }} {{ $props.currentUser.username | capitalize }}</p>
            <b-form-textarea
                id="textarea"
                v-model="note"
                :placeholder="$t('opmerkingen.opmerkingen')"
                rows="3"
            ></b-form-textarea>
            <div class="text-right">
              <b-button class="mt-2" :disabled="(this.note.length <= 0)" v-on:click="addNote()">
                {{ $t('opmerkingen.opmerkingopslaan') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import ReservationsService from '../services/reservations.service';

export default {
  props: ['reservation', 'currentUser'],
  name: 'ReservationNotes',
  data() {
    return {
      notes: [],
      note: '',
      fields: [
        {
          key: 'opmerking',
          label: this.$t('opmerkingen.opmerkingen'),
          formatter: (value, key, item) => {
            if(item.linkto){
              const parts = value.split(/(\?|&)([^=]+)=([^&']+)/)
              parts[0] = ''
              parts[1] = ''
              parts[2] = ''
              parts[3] = ''
              parts[4] = parts[4].replace('\'>','').replace('</a>', '')
              return parts.join('').toString()
            }else{
              return `${value}`
            }
          },
          sortable: false,
          thClass: "pl-3",
          tdClass: "pl-3"

        }, {
          key: 'datum',
          label: this.$t('alg.datum'),
          sortable: true,
        }, {
          key: 'medewerker',
          label: this.$t('alg.medewerker'),
          sortable: true
        }
      ]
    };
  },
  computed: {},
  methods: {
    getNotes() {
      ReservationsService.getnotes(this.$props.reservation.shophid, this.$props.reservation.hash)
          .then(
              data => {
                this.notes = data
              })
          .catch(
              err => {
                this.toast('err', err)
              })
    },
    addNote() {
      if (this.note === '' || this.note === null) {
        return
      } else {
        ReservationsService.addnote(this.$props.reservation.shophid, this.$props.reservation.hash, this.note)
            .then(
                data => {
                  this.notes = data
                  this.note = ''
                })
            .catch(
                err => {
                  this.toast('err', err)
                })
      }
    }

  },
  created() {
    this.$parent.$on('refreshReservationsOverview',
        () => {
          this.getNotes()
        })
    this.getNotes()
  },

}
</script>

<style lang="scss" scoped>
.resopmerking{
  font-family: $font-family-base !important;
  font-style: italic;
  font-size: initial;
  font-weight: normal;
}
.border-grey {
  border-color: rgba(0, 0, 0, 0.125);
}

.spantoggle {
  &:focus {
    outline: none;
  }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
