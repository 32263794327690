<template>
  <div class="">
    <b-modal
        :visible="currentMaterialId !== null"
        hide-footer
        @hide="closeMaterialModal()"
    >
      <template #modal-header=" {close} ">
        <div class="container-fluid">
          <b-row>
            <b-col cols="6">
              <h3>{{ $t("materiaal.materiaal") }}</h3>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button size="sm" variant="outline-warning" @click="close()">
                {{ $t("alg.sluiten") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <template #default>
        <material-details :material-id="currentMaterialId"></material-details>
      </template>
    </b-modal>
    <b-card header-tag="header" class="mb-2 border-0 shadow-sm" no-body>
      <template #header>
        <div class="d-flex justify-content-between">
          <div>
            <span class="spantoggle" v-b-toggle:collapsematerials >
              <b-icon icon="chevron-up" class="when-open"></b-icon>
              <b-icon icon="chevron-down" class="when-closed"></b-icon>
              <span>
                {{ $t("materiaal.materiaal") }}
              </span>
            </span>
          </div>
          <b-button
              size="sm"
              @click="toggleScan()"
              :pressed="showScan() ? true : null"
          >
            <b-icon icon="upc-scan" font-scale="0.95"></b-icon>
          </b-button>

        </div>
      </template>
      <b-collapse id="collapsematerials" v-model="showMaterials">
        <b-card-body class="">
          <div v-if="materialsList.length > 0">
            <b-col cols="12">
              <b-row class="d-flex flex-row">
                <div class="p-1"
                     v-for="item in materialsList"
                     v-bind:key="item.id_materiaal"
                >
                  <div class="d-flex d-inline-flex p-2 bg-secondary rounded border-primary">
                    <div class="p-1 font-weight-bolder">{{ item.label }}</div>
                    <div class="bg-info p-1 pl-2 pr-2 ml-1">
                      <b-icon @click="currentMaterialId = item.id_materiaal" scale="2" icon="info"
                              variant="light"></b-icon>
                    </div>
                    <div class="bg-info p-1 pl-1 pr-1 ml-1">
                      <b-iconstack font-scale="1.4"
                                   @click="removeMaterial(item.id_materiaal)"
                      >
                        <b-icon stacked icon="link" variant="light" scale="1"></b-icon>
                        <b-icon stacked icon="slash-circle" variant="danger"></b-icon>
                      </b-iconstack>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import ScanAndGoService from '../services/scanandgo.service';
import MaterialDetails from "@/components/MaterialDetails";

export default {
  name: 'ReservationsMaterial',
  components: {MaterialDetails},
  data() {
    return {
      materialsList: [],
      currentMaterialId: null,
      showMaterials: true,
    }
  },
  computed: {
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    currentResHID() {
      return this.$store.getters["bus/currentResHID"]
    },
    media() {
      return {
        'is-phone': this.$screen.sm,
        'is-tablet': this.$screen.md,
        'is-desktop': this.$screen.lg,
        'can-touch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
  methods: {
    async getForThisRes() {
      try {
        if(this.currentResHID !== null){
          this.materialsList = await ScanAndGoService.listByReservation(this.currentShop['hashcode'], this.currentResHID)
          this.showMaterials = this.materialsList && this.materialsList.length > 0;
        }
      } catch (err) {
        this.toast('err', err)
      } finally {
        this.material = null
      }
    },
    toggleScan() {
      if (this.showScan()) {
        this.$store.dispatch("bus/closeScan")
      } else {
        this.$store.dispatch("bus/openScan")
      }
    },
    showScan() {
      return this.$store.getters["bus/showScan"]
    },
    closeMaterialModal() {
      this.currentMaterialId = null
      this.getForThisRes()
    },
    async removeMaterial(id) {
      const result = await ScanAndGoService.unsetFromReservation(this.currentShop['hashcode'], id)
      if (result.code === 2065) {
        this.toast('success', 'Materiaal logekoppeld van reservering!', 'Losgekoppeld!')
      } else {
        this.toast('err', result.error)
      }
      await this.getForThisRes()
    },
    showInfo(id) {
      console.log(id)
    }
  },
  created() {
    this.getForThisRes()
    this.$parent.$on('refreshMaterials', this.getForThisRes)
    this.$parent.$on('refreshReservationsOverview', this.getForThisRes)
  },
}
</script>
<style lang="scss" scoped>
.spantoggle{
  &:focus{
    outline: none;
  }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>
