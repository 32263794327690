<template>
  <div class="scancontainer position-relative">
    <template v-if="scanMode === 'Camera'">
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit"/>
      <div id="layover" :class="msgclass">
            <div class="w-100 text-center"> {{ scanmsg }}</div>
            <b-progress class="msgResetProgress position-absolute fixed-bottom" :value="msgResetProgress" variant="info"></b-progress>
      </div>
    </template>

    <template v-if="scanMode === 'TextScanner'">
        <div id="scanresult" :class="msgclass">
          <div>{{ scanmsg }}</div>
        </div>
        <b-progress class="msgResetProgress" :value="msgResetProgress" variant="info"></b-progress>
        <div>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="upc-scan"></b-icon>
            </b-input-group-prepend>
            <b-form-input ref="scanTextInput" v-model="scanTextInput"></b-form-input>
          </b-input-group>
        </div>
    </template>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import ScanAndGoService from '../services/scanandgo.service';

const beep = new Audio(require("@/assets/sounds/beep.mp3"));
const beepOK = new Audio(require("@/assets/sounds/beep-ok.wav"));

export default {
  name: 'QRScanner',
  data() {
    return {
      camera: 'auto',
      scanmsg: '',
      msgclass: 'neutral',
      busy: false,
      msgResetTimer: null,
      msgResetProgressInterval: null,
      msgResetProgress: 0,
      scanMode: JSON.parse(localStorage.getItem('lk.scanDevice')) || 'Camera',
      scanTextInput: '',
      closeMe: false
    }

  },
  props:{
    currentReshid : {
      type: String,
      default: null
    },
    doneFunction : {
      type: Function,
      default: null
    },
    successFunction : {
      type: Function,
      default: null
    }
  },
  components: {
    QrcodeStream
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/currentUser"]
    },
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
  },
  methods: {
    onDecode(result) {
      this.msgclass = 'processing';
      this.camera = 'off';
      const regex = /[!-/:-@[-`{-~]/;
      if (result.length > 6 && (regex.test(result))) {
        if (this.busy === true) {
          //scan negeren met dubbele scans
          return;
        }
        this.busy = true
        beep.play()
        result = result.replace('*','')
        ScanAndGoService.scan(this.currentShop['hashcode'], result)
            .then((d) => {
              if (d.result === "NOTICE") {
                this.msgclass = 'notice';
                this.scanmsg = d.data;
                this.setMsgReset();
              } else if (d.result === "ERROR") {
                this.msgclass = 'error';
                this.scanmsg = d.data;
                this.setMsgReset();
              } else if (d.result === "OK") {
                beepOK.play()
                this.msgclass = 'success';
                this.scanmsg = d.data;
                this.setMsgReset();
              } else if (d.result === "REDIRECT") {
                if (d.resHID) {
                  this.msgclass = 'success'
                  this.scanmsg = 'Reservering gescand'
                  if(typeof this.$props.successFunction === 'function'){
                    this.$props.successFunction(d.resHID)
                  } else {
                    this.$store.dispatch("bus/changeRes", d.resHID)
                    this.$router.push(`/reservations/${d.resHID}`)
                  }
                  this.setMsgReset(true, 1000);
                } else if (d.data.material) {
                  // eslint-disable-next-line no-constant-condition
                  if (this.$props.currentReshid !== null) {
                    ScanAndGoService.setToReservation(this.currentShop['hashcode'], this.$props.currentReshid, d.data.material.id_materiaal).then(
                        (res) => {
                          if (res.code === 2074) {
                            this.msgclass = 'success';
                            beepOK.play()
                            this.scanmsg = 'Materiaal Toegevoegd';
                            this.setMsgReset(true, 1000);
                          } else if (res.notice) {
                            this.msgclass = 'notice';
                            this.scanmsg = res.notice;
                            this.setMsgReset();
                          }else{
                            this.msgclass = 'error';
                            this.scanmsg = res.error;
                            this.setMsgReset();
                          }
                        },
                        (err) => {
                          this.msgclass = 'error';
                          this.scanmsg = err.toString();
                          this.busy = false;
                          this.setMsgReset();
                        }
                    );
                  } else {
                    this.msgclass = 'success';
                    this.scanmsg = d.data.material.label;
                    this.setMsgReset(true, 1000);
                    setTimeout(()=>{
                      this.$router.push('/material/' + d.data.material.id_materiaal)
                    }, 1000)
                  }
                }
              } else {
                this.msgclass = 'error';
                this.scanmsg = 'no action';
                this.setMsgReset();
              }
            })
            .catch((err) => {
              this.msgclass = 'error';
              console.log(err)
              this.scanmsg = 'Invalid Input!';
              this.setMsgReset();
            })
      } else {
        this.msgclass = 'error';
        this.scanmsg = 'Invalid Input!';
        this.setMsgReset();
      }
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        this.msgclass = 'error'
        if (error.name === 'NotAllowedError') {
          this.scanmsg = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.scanmsg = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.scanmsg = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.scanmsg = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.scanmsg = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.scanmsg = "ERROR: Stream API is not supported in this browser"
        }
      }
    },

    setMsgReset(closeMe, timeout) {
      this.closeMe = closeMe === true
      if(timeout === undefined){ timeout = 3000 }
      if (this.msgResetTimer === null) {
        this.msgResetProgressInterval = setInterval(() => {
          this.msgResetProgress = this.msgResetProgress + 2.2
        }, timeout / 100)
        this.msgResetTimer = setTimeout(() => {
          clearInterval(this.msgResetProgressInterval)
          this.msgclass = 'neutral'
          this.msgResetProgress = 0
          this.scanmsg = ''
          this.scanTextInput = ''
          this.camera = 'auto'
          this.busy = false
          this.msgResetTimer = null
          if(this.closeMe){
            if(typeof this.$props.doneFunction === 'function'){
              this.$props.doneFunction()
            }else{
              this.$store.dispatch("bus/closeScan")
            }
          }
        }, timeout)
      }
    },

    catchKeys(e) {
      switch (e.key) {
        case '=':
          this.$refs.scanTextInput.focus()
          break;
        case 'Enter':
        case '*':
          setTimeout(this.onDecode(this.scanTextInput),100)
          break;
      }
    }
  },
  created() {
    window.addEventListener('keydown', this.catchKeys);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.catchKeys);
  }
}
</script>

<style scoped lang="scss">
.scancontainer {
  width: 100%;
}

.msgResetProgress {
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0.3);
}

#scanresult {
  padding-left: 1em;
  padding-right: 1em;
  min-height: 15vh;
  text-align: center;
  font-weight: bolder;
}

#layover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  min-height: 15vh;
  color: white;

  div {
    text-align: center;
  }
}

.neutral {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.processing {
  background: rgba($lk_blue, 0.5);
  color: white;
}

.notice {
  background: rgba($lk_orange, 0.5);
  color: black;
}

.success {
  background: rgba($lk_green, 0.5);
  color: white;
}

.error {
  background: rgba($lk_red, 0.5);
  color: white;
}
</style>
