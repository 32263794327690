<template>
  <div id="ReservationDetails" :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
    <b-card header-tag="header" header-class="" class="mb-2 border-0 shadow-sm">
      <template #header>
        <div class="d-flex flex-wrap justify-content-between">
          {{ $t('alg.reserveringsdetails') }}
          <div class="d-flex justify-content-end" v-if="hasReservationCrudRights">
            <b-button v-if="currentShop.options.borg" ref="addBorg" v-b-modal.modal-borg size="sm" class="mr-3">
              <span class="text-no-break">{{ $t('resdetails.borg') }}</span>
            </b-button>

            <div v-if="reservation.isSubReservation || reservation.isLocked"></div>
            <b-button v-else ref="addDiscountCode" v-b-modal.modal-discountcode size="sm" class="mr-3">
              <span class="text-no-break">{{ $t('resdetails.kortingscode') }}</span>
            </b-button>

            <div v-if="reservation.isSubReservation || reservation.isLocked"></div>
            <b-button v-else ref="addButton" size="sm" v-b-modal.modal-addrow>
              <span class="text-no-break">{{ $t('alg.voegtoe') }}</span>
            </b-button>
          </div>
        </div>
      </template>
      <b-modal
          id="modal-addrow"
          ref="modal-addrow"
          size="lg"
          no-stacking
          ok-only
          ok-variant="secondary"
          :title="$options.filters.capitalize($t('alg.toevoegen'))"
          ok-title="Cancel"
          @show="resetModalAddRow"
          @hidden="resetModalAddRow"
          @ok="resetModalAddRow"
      >
        <AddReservationLines
            v-on:newRowsAdded="newRowsAdded"
            :propdate="newReservationLineDate"
        ></AddReservationLines>
      </b-modal>
      <b-modal
          id="modal-discountcode"
          ref="modal-discountcode"
          :title="$t('resdetails.kortingscodeinvoeren')"
          :ok-title="dccOk ? $t('alg.voegtoe') : 'Check code'"
          @show="resetModalDiscount"
          @hidden="resetModalDiscount"
          @ok="handleModalDiscount"
      >
        <form ref="form" @submit.stop.prevent="handleModalDiscount" class="mb-2">
          <b-form-group
              id="fieldset-1"
              description=""
              label=""
              label-for="dcc-input"
              :invalid-feedback="$t('validationmessages.minlength', ['3'])"
              :state="dccInputValid"
          >
            <b-form-input
                ref="modal-discountcode-input"
                id="dcc-input"
                v-model="dccode"
                :state="dccInputValid"
            ></b-form-input>
          </b-form-group>
        </form>
        <div v-if="dccodeResult" class="w-100 p-3 text-center text-white h5 rounded" :class="`bg-${dcccodeClass}`">
          <span class="font-weight-bold" v-html="dccodeResult"></span>
        </div>
      </b-modal>
      <b-modal
          id="modal-borg"
          ref="modal-borg"
          :ok-title="hasBorg ? $options.filters.capitalize($t('betalingen.terugbetalen')) : 'OK'"
          @show="resetModalBorg"
          @hidden="resetModalBorg"
          @ok="handleModalBorg"
      >
        <template #modal-title>
          <template v-if="!hasBorg">{{ $t('resdetails.borg') }} {{ $t('alg.toevoegen') }}</template>
          <template v-if="hasBorg">{{ $t('resdetails.borg') }} {{ $t('betalingen.terugbetalen') }}</template>
        </template>
        <form ref="form" @submit.stop.prevent="handleModalBorg" class="mb-2">
          <template v-if="!hasBorg">
            <b-form-group
                id="fieldset-2"
                description=""
                label=""
                label-for="borg-input"
                :invalid-feedback="$t('validationmessages.minamount', ['1.00'])"
                :state="borgInputValid"
            >
              <p>{{ $t('betalingen.bedrag') }}</p>
              <b-input-group prepend="€" append=",00" v-if="$props.reservation">
                <input v-currency="{
                  locale: 'nl',
                  currency: null,
                  valueAsInteger: true,
                  distractionFree: true,
                  precision: 0,
                  autoDecimalMode: true,
                  allowNegative: true
                   }"
                       id="borg-input"
                       ref="modal-borg-input"
                       v-model="borgAmount"
                       class="form-control"
                       :state="borgInputValid"
                />
              </b-input-group>
            </b-form-group>
            <b-button-group class="w-100">
              <b-button class="border-dark" @click="borgAmount=20">20</b-button>
              <b-button class="border-dark" @click="borgAmount=50">50</b-button>
              <b-button class="border-dark" @click="borgAmount=100">100</b-button>
              <b-button class="border-dark" @click="borgAmount=150">150</b-button>
              <b-button class="border-dark" @click="borgAmount=200">200</b-button>
              <b-button class="border-dark" @click="borgAmount=300">300</b-button>
            </b-button-group>
          </template>
          <template v-if="hasBorg">
            {{ $t('betalingen.zekerwetenborgterug') }}
          </template>
        </form>
      </b-modal>
      <b-card-group columns>
        <b-overlay
            v-for="(row, index) in orderLines"
            v-bind:key="row.id"
            :id="`rescard_${index}`"
            :show="mustConfirmChangesId !== null"
            variant="dark"
            opacity=0.85
            blur="px"
            rounded="sm"
        >
          <b-card
              class="orderline shadow-sm"
              :class="[(row.edit === false) ? 'hover' : 'border border-primary', islargeCard(row) ? 'largeCard' : '']"
              @click.prevent="editRow(index)"
              v-click-outside="onClickOutsideRow"
              footer-tag="footer"
          >
            <div v-if="showField(row, 'date')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('alg.datum') }}</label>
              </div>
              <div class="orderline-input">
                <b-datepicker
                    :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                    v-model="activeRow.date"
                    :value="activeRow.date"
                    class="rowEl"
                    v-if="row.edit && isEditing"
                    :disabled="activeRow.isEditable === false"
                    v-on:input="setDirty(setCurrentAssortiment)"
                ></b-datepicker>
                <div v-else>
                  <div class="text-no-break">
                    {{ row.date | moment("DD-MM-YYYY") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showField(row, 'activity')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('alg.activiteit') }}</label>
              </div>
              <div class="orderline-input">
                <b-form-select
                    v-model="activeRow.id_assortiment"
                    v-on:change="setDirty(setCurrentAssortiment)"
                    v-if="row.edit && isEditing "
                    :disabled="activeRow.isEditable === false"
                    class="rowEl form-control"
                >
                  <option value="null" disabled>Selecteer</option>
                  <option
                      v-for="options in assortmentList"
                      v-bind:key="options.id_assortiment"
                      :value="options.id_assortiment"
                      class="rowEl"
                  >{{ options.weergavenaam }}
                  </option>
                  <option value="1161" disabled>Servicekosten</option>
                  <option value="4582" disabled>Korting</option>
                  <option value="8654" disabled>Borg</option>
                </b-form-select>
                <div v-else class="text-no-break">{{ row.data.assortmentData.weergavenaam }}</div>
              </div>
            </div>
            <div v-if="showField(row, 'slots')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('resdetails.blok') }}/{{ $t('resdetails.tijdsduur') }}</label>
              </div>
              <div class="orderline-input">
                <b-form-select
                    v-model="activeRow.id_block"
                    v-if="row.edit && isEditing && activeRow.id_assortiment !== null"
                    v-on:change="setDirty(setCurrentSlot(activeRow.id_block))"
                    :disabled="activeRow.isEditable === false"
                    class="rowEl form-control"
                >
                  <template v-if="activeRow.isEditable === false">
                    <option :value="row.data.blockData.ID_Block">{{ row.data.blockData.Blockname }}</option>
                  </template>
                  <template v-if="activeRow.isEditable === true">
                    <option
                        v-for="slot in slots"
                        v-bind:key="slot.ID_Block"
                        :value="parseInt(slot.ID_Block)"
                        class="rowEl"
                    >{{ slot.Blockname }}
                    </option>
                  </template>
                </b-form-select>
                <div v-else class="text-no-break">{{ row.data.blockData.Blockname }}</div>
              </div>
            </div>
            <div v-if="showField(row, 'time')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('alg.tijd') }}</label>
              </div>
              <div class="orderline-input">
                <template v-if="row.edit && isEditing && parseInt(activeRow.data.assortmentData.starttijden) === 2">
                  <!-- tijdsduur dus timepicker  per kwartier, zie api documentatie -->
                  <b-form-select
                      v-model="activeRow.starttime"
                      v-if="row.edit && isEditing && activeRow.data.blockData.timeselection"
                      v-on:change="setDirty(setCurrentStarttime())"
                      :disabled="activeRow.isEditable === false"
                      class="rowEl"
                  >
                    <b-form-select-option
                        v-for="option in activeRow.data.blockData.timeselection"
                        v-bind:key="option.BlockStart"
                        :value="option.BlockStart"
                        class="rowEl form-control"
                    >{{ option.BlockStart.substring(0, 5) }} - {{ option.BlockEnd.substring(0, 5) }}
                    </b-form-select-option>
                  </b-form-select>
                </template>
                <template v-if="row.edit && isEditing && parseInt(activeRow.data.assortmentData.starttijden) === 1">
                  <b-form-input
                      class="rowEl"
                      readonly
                      :value="`${activeRow.starttime.substring(0, 5)} - ${activeRow.endtime.substring(0, 5)}`"
                  >
                  </b-form-input>
                </template>
                <div v-if="!row.edit" class="text-no-break">{{ row.starttime.substring(0, 5) }} -
                  {{ row.endtime.substring(0, 5) }}
                </div>
              </div>
            </div>
            <div v-if="showField(row, 'tarif')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('resdetails.tarief') }}</label>
              </div>
              <div class="orderline-input">
                <b-form-select
                    v-model="activeRow.id_tarif"
                    v-if="row.edit && isEditing && activeRow.id_block !== null"
                    v-on:change="setDirty(setCurrentTarif(activeRow.id_tarif))"
                    :disabled="activeRow.isEditable === false"
                    class="rowEl form-control"
                >
                  <template v-if="activeRow.isEditable === false">
                    <option :value="row.data.tarifData.id_tarif">{{ row.data.tarifData.description }}</option>
                  </template>
                  <template v-if="activeRow.isEditable === true">
                    <option
                        v-for="tarif in tarifs"
                        v-bind:key="tarif.id_tarif"
                        :value="tarif.id_tarif"
                        class="rowEl"
                    >{{ tarif.description }}
                    </option>
                  </template>
                </b-form-select>
                <div v-else class="text-no-break">{{ row.data.tarifData.description }}</div>
              </div>
            </div>
            <div v-if="showField(row, 'price')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('alg.prijs') }}</label>
              </div>
              <div class="orderline-input">
                <b-input-group
                    v-if="row.edit && isEditing"
                    class="rowEl"
                >
                  <b-input-group-prepend class="prepend">
                    <span v-html="row.data.assortmentData.valuta_teken"></span>
                  </b-input-group-prepend>
                  <currency-input
                      v-model="activeRow.price"
                      :disabled="activeRow.isEditable === false"
                      class="rowEl form-control rounded-right"
                      v-on:input="setDirty()"
                  />
                </b-input-group>
                <div v-else class="text-no-break"><span v-html="row.data.assortmentData.valuta_teken"></span>
                  {{ row.price | money(row.data.assortmentData.valuta_id) }}
                </div>
              </div>
            </div>
            <div v-if="showField(row, 'pax')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('resdetails.aantal') }}</label>
              </div>
              <div class="orderline-input">
                <b-input-group v-if="row.edit && isEditing ">
                  <b-input-group-prepend>
                    <b-input-group-text
                        class="rowEl"
                        v-on:click="chgPax('down')"
                    >
                      <div>-</div>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                      :readonly="activeRow.isEditable === true"
                      min="0"
                      :max="max"
                      v-model="activeRow.pax"
                      :disabled="activeRow.isEditable === false"
                      class="rowEl"
                      :class="{'bg-white' : activeRow.isEditable}"
                      v-on:input="setDirty()"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-input-group-text
                        class="rowEl"
                        v-on:click="chgPax('up')"
                    >
                      <div>+</div>
                    </b-input-group-text>
                    <b-input-group-text
                        class="rowEl"
                    >
                        <span v-bind:class="(max - activeRow.pax <= 0 ? 'text-danger' : 'text-success')">{{
                            (max > 99999 ? '&#8734;' : (max + activeRow.orgPax) - activeRow.pax)
                          }}</span>
                    </b-input-group-text>
                  </b-input-group-append>
                  <!--            {{ max }}-->
                  <!--            {{ activeRow.orgPax}}-->
                  <!--            {{ activeRow.pax}}-->

                </b-input-group>
                <div v-else class="text-no-break">{{ row.pax }}</div>
              </div>
            </div>
            <div v-if="showField(row, 'total')" class="field">
              <div class="orderline-label">
                <label class="small">{{ $t('resdetails.totaal') }}</label>
              </div>
              <div class="orderline-input">
                <b-input-group
                    v-if="row.edit && isEditing "
                    disabled
                    class="rowEl"
                >
                  <b-input-group-prepend class="prepend">
                    <span v-html="row.data.assortmentData.valuta_teken"></span>
                  </b-input-group-prepend>
                  <b-form-input
                      type="number"
                      class="rowEl"
                      readonly
                      :value="((activeRow.price * activeRow.pax) / 100).toFixed(2)"
                  >
                  </b-form-input>
                </b-input-group>
                <div v-else class="text-no-break"><span v-html="row.data.assortmentData.valuta_teken"></span>
                  {{ row.pax * row.price | money(row.data.assortmentData.valuta_id) }}
                </div>
              </div>
            </div>
            <template #footer v-if="activeRow && index === activeRow.id">
              <div class="d-flex justify-content-between">
                <b-button
                    v-if="isDirty"
                    variant="info"
                    size="sm"
                >
                  <b-icon @click="onEditEndRow" icon="pencil-square" variant="white" class="rowEl"></b-icon>
                </b-button>
                <div class="text-warning">
                        <span v-if="activeRow.warning">
                          <b-icon icon="exclamation-circle-fill"></b-icon>
                          {{ activeRow.warning }}
                        </span>
                </div>
                <b-button
                    variant="danger"
                    :disabled="orderLines[index].isDeletable === false"
                    v-on:click.stop="removeRow(index)"
                    size="sm"
                    class="rowEl"
                >
                  <b-icon icon="trash" variant="white" class="rowEl"></b-icon>
                </b-button>
              </div>
            </template>
          </b-card>
          <template #overlay>
            <b-row v-if="index !== mustConfirmChangesId">
              <div></div>
            </b-row>
            <b-row v-if="index === mustConfirmChangesId">
              <b-col cols="12">
                <div class="d-flex justify-content-between">
                  <div>
                    <b-button
                        v-if="!orderLines[index].deleteMe"
                        ref="save"
                        variant="success"
                        size="md"
                        @click="saveToBackend"
                        class="mr-5"
                    >
                      <b-icon icon="check-square"></b-icon>
                      {{ $t('resdetails.opslaan') }}
                    </b-button>
                    <b-button
                        v-if="orderLines[index].deleteMe"
                        ref="save"
                        variant="danger"
                        size="md"
                        @click="saveToBackend"
                        class="mr-5"
                    >
                      <b-icon icon="dash-square"></b-icon>
                      {{ $t('resdetails.verwijderen') }}
                    </b-button>
                  </div>
                  <div>
                    <b-button
                        v-if="!orderLines[index].deleteMe"
                        ref="cancel"
                        variant="warning"
                        size="md"
                        @click="resetToOriginal"
                        class=""
                    >
                      <b-icon icon="caret-left-square"></b-icon>
                      {{ $t('resdetails.terugnaarorigineel') }}
                    </b-button>
                    <b-button
                        v-if="orderLines[index].deleteMe"
                        ref="cancel"
                        variant="warning"
                        size="md"
                        @click="resetToOriginal"
                        class=""
                    >
                      <b-icon icon="exclamation-square"></b-icon>
                      {{ $t('resdetails.annuleren') }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-overlay>
      </b-card-group>
    </b-card>
    <b-card v-if="reservation.isBooking" class="mb-2" no-body>
      <template #header>
        <div class="d-flex justify-content-start">
          <span class="spantoggle" v-b-toggle:collapseBookingDetails>
              <b-icon icon="chevron-up" class="when-open"></b-icon>
              <b-icon icon="chevron-down" class="when-closed"></b-icon>
              <span>
                {{ $t('alg.boeking') }}
              </span>
          </span>
        </div>
      </template>
      <b-collapse id="collapseBookingDetails">
        <b-card-body v-if="!reservation.isSubReservation">
          <b-card-group deck>
            <b-card
                v-for="(resdata, idres) in reservation.subReservationData"
                v-bind:key="idres"
                no-body
            >
              <template v-slot:header>
                {{resdata.internalkey}}
                <span class="header-normal ml-sm-4">{{resdata.shop.name}}</span>
                <span class="header-normal ml-sm-4" v-if="resdata.shop.phone"><small> ({{resdata.shop.phone}})</small></span>
              </template>
              <b-card-body body-class="p-0">
                <b-list-group flush>
                  <b-list-group-item
                      v-for="(d, key) in resdata.details"
                      v-bind:key="key"
                  >
                    <b-row>
                      <b-col lg="2" md="3" sm="6" cols="6">{{d.startdatum |  moment('DD-MM-YYYY') }}</b-col>
                      <b-col lg="2" md="3" sm="6" cols="6">{{ d.starttijd.substring(0, 5) }} - {{ d.eindtijd.substring(0, 5) }}</b-col>
                      <b-col lg="2" md="6" sm="12" cols="12">{{d.assortiment.weergavenaam}}</b-col>
                      <b-col lg="2" md="3" sm="3" cols="3">{{d.aantal_gereserveerd}}</b-col>
                      <b-col lg="2" md="3" sm="3" cols="3"><span v-html="resdata.shop.currency.sign"></span> {{parseFloat(d.prijs_per_stuk) * 100 | money(resdata.shop.currency.id)}}</b-col>
                      <b-col lg="2" md="3" sm="3" cols="3"><span v-html="resdata.shop.currency.sign"></span> {{ (parseInt(d.aantal_gereserveerd) * parseFloat(d.prijs_per_stuk)) * 100 | money(resdata.shop.currency.id)}}</b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-card-body>
        <b-card-body v-if="reservation.isSubReservation">
            <b-card
                no-body
            >
              <template v-slot:header>
                {{reservation.mainReservationData.internalkey}}
                <span class="header-normal">{{reservation.mainReservationData.shop.name}}</span>
                <span class="header-normal" v-if="reservation.mainReservationData.shop.phone"><small> ({{reservation.mainReservationData.shop.phone}})</small></span>
              </template>
            </b-card>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import AssortimentService from '@/services/assortiment.service';
import ReservationsService from '@/services/reservations.service';
import ShopsService from '@/services/shops.service';
import AddReservationLines from '@/components/AddReservationLines'

export default {
  props: ['reservation'],
  name: 'ReservationDetails',
  components: {AddReservationLines},
  data() {
    return {
      startDate: new this.$moment,
      reservation_details: [],
      assortmentList: [],
      orderLines: [],
      orderLinesOrg: [],
      dataLoader: [],
      tarifs: [],
      slots: [],
      activeRow: null,
      max: 0,
      dccode: null,
      dccodeResult: null,
      dcccodeClass: 'info',
      dccOk: false,
      borgAmount: 0,
      isSaving: false,
      isEditing: false,
      isDirty: false,
      newElisRow: false,
      isHandelingClick: false,
      mustConfirmChangesId: null,
      detailCheckInterval: null,
      loadTimestamp: null,
    }
  },
  computed: {
    dccInputValid() {
      return this.dccode !== null && this.dccode.length > 2
    },
    borgInputValid() {
      return this.borgAmount >= 1
    },
    newReservationLineDate() {
      const dates = this.orderLines.map(item => item.date).sort((a, b) => new Date(b) - new Date(a))
      if (dates[0]) {
        return dates[0]
      } else {
        return new this.$moment().format("YYYY-MM-DD")
      }
    },
    hasBorg() {
      if(this.$props.reservation.details && this.$props.reservation.details.length > 0){
        return this.$props.reservation.details.filter(item => item.id_assortiment === 8654).length > 0
      }
      return false
    },
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    hasReservationCrudRights() {
      return this.$store.getters['user/hasRight']("reservation_crud");
    }
  },
  methods: {
    //fill order lines
    async fillOrderlines() {
      await this.clearActiveRows()

      this.orderLines = []
      this.orderLinesOrg = []
      let details = this.reservation.details
      for (const [i, item] of Object.entries(details)) {
        let temp = {
          id: parseInt(i),
          orgId: item.id,
          id_assortiment: item.id_assortiment,
          date: item.startdatum,
          starttime: item.starttime,
          endtime: item.endtime,
          id_block: item.id_block,
          id_tarif: item.id_tarif,
          price: item.price,
          pax: item.pax,
          orgPax: item.pax,
          data: {
            assortmentData: {
              weergavenaam: ''
            },
            blockData: {
              Blockname: ''
            },
            tarifData: {
              description: ''
            }
          },
          edit: false,
          isEditable: item.isEditable,
          isDeletable: item.isDeletable
        }
        this.orderLines.push(temp)
        this.getOrderlineData(temp, i)
      }
    },
    //datagetters
    async getAssortiment() {
      try {
        this.assortmentList = this.$store.getters["misc/getAssortimentsData"][this.$store.getters["bus/currentShop"].hashcode]
      } catch (e) {
        this.resdetails = []
        this.toast('err', e.toString())
      }
    },
    async getBlokken(id_assortiment, date) {
      try {
        const slotdata = await AssortimentService.getSlots(id_assortiment, date)
        if (slotdata.StockStatus === 'N' || slotdata.StockStatus === 'C') {
          if (slotdata.StockDetails) {
            let warn = slotdata.StockDetails
            if (this.$i18n.locale === 'nl') {
              if (warn === 'Booking margin applied!') {
                warn = 'Boekingsmarge van toepassing!'
              }
              if (warn === 'Shop Closed!') {
                warn = 'Winkel gesloten!'
              }
            }
            this.$set(this.activeRow, 'isEditable', false)
            this.$set(this.activeRow, 'warning', warn)
            return
          }
        }
        if(slotdata.StockStatus === 'U'){
          this.max = Number.MAX_SAFE_INTEGER
          return
        }
        this.slots = slotdata
        for (const slot in this.slots) {
          if (parseInt(this.slots[slot].ID_Block) === this.activeRow.id_block) {
            this.setCurrentSlot(this.activeRow.id_block)
            return
          }
        }
        this.setCurrentSlot(this.slots[0].ID_Block)
      } catch (err) {
        this.resDetails = []
        this.toast('err', err)
      }
    },
    async getDetails(id_assortiment) {
      try {
        this.activeRow.data.assortmentData = await AssortimentService.getDetails(id_assortiment)
      } catch (err) {
        this.toast('err', err)
      }
    },
    async getTarif(id_slot) {
      try {
        this.tarifs = await AssortimentService.getTarif(this.activeRow.id_assortiment, id_slot)
        this.setCurrentTarif(this.tarifs[0].id_tarif)
      } catch (err) {
        this.resDetails = []
        this.toast('err', err)
      }
    },
    async getOrderlineData(row, index) {
      let vm = this
      try {
        vm.orderLines[index].data.assortmentData = await AssortimentService.getDetails(row.id_assortiment)
      } catch (err) {
        this.toast('err', err)
      }
      try {
        vm.orderLines[index].data.blockData = await AssortimentService.getBlockData(row.id_assortiment, row.id_block)
      } catch (err) {
        this.toast('err', err)
      }
      try {
        const resultTarifData = await AssortimentService.getTarif(row.id_assortiment, row.id_block)

        await resultTarifData.forEach(item => {
          if (item.id_tarif === row.id_tarif) {
            vm.$set(vm.orderLines[index].data, "tarifData", item)
          }
        });
      } catch (err) {
        this.toast('err', err)
      }

      this.orderLinesOrg[index] = JSON.parse(JSON.stringify(this.orderLines[index]))
    },
    async getStockDetails(starttime) {
      try {
        let availableStock = await AssortimentService.getStockDetails(this.activeRow.id_assortiment, this.activeRow.id_block, this.activeRow.date, starttime)
        this.max = availableStock.Free
      } catch (err) {
        this.toast('err', err)
      }
    },
    //data setters on form change
    setDirty(callback) {
      this.isDirty = true
      if (callback) {
        callback()
      }
    },
    setCurrentAssortiment() {
      if (this.activeRow.id_assortiment === null) {
        return
      }
      let id_assortiment = this.activeRow.id_assortiment
      this.activeRow.data.assortmentData = this.assortmentList.filter(item => item.id_assortiment === id_assortiment)[0]

      let date = this.activeRow.date
      this.slots = []
      this.tarifs = []
      this.getBlokken(id_assortiment, date);
      this.getDetails(id_assortiment)
    },
    setCurrentSlot(id_slot) {
      this.activeRow.id_tarif = null
      this.activeRow.starttime = null
      this.activeRow.endtime = null
      // this.activeRow.price = null
      this.activeRow.id_block = id_slot

      this.activeRow.data.blockData = this.slots.filter(item => parseInt(item.ID_Block) === parseInt(id_slot))[0]

      this.activeRow.starttime = this.activeRow.data.blockData.BlockStart
      this.activeRow.endtime = this.activeRow.data.blockData.BlockEnd

      if (parseInt(this.activeRow.data.assortmentData.starttijden) === 2) {
        //starttijden, eerst startijd en duration, dan voorraad ophalen
        this.activeRow.data.blockData.timeselection = []

        const extraDays = parseInt(this.activeRow.data.blockData.ExtraDays)
        const blockDuration = parseInt(this.activeRow.data.blockData.DurationMinutes);
        let lastStart = new this.$moment(this.activeRow.data.blockData.DurationLastStart, 'HH:mm:ss')
        const firstStart = new this.$moment(this.activeRow.data.blockData.BlockStart, 'HH:mm:ss')

        let currentTime = new this.$moment(firstStart)
        while (currentTime.isSameOrBefore(lastStart)) {
          let end = new this.$moment(currentTime).add(blockDuration, 'minutes')
          if (blockDuration === 0 || extraDays === -1) {
            end = new this.$moment(this.activeRow.data.blockData.BlockEnd, 'HH:mm:ss')
          }
          this.activeRow.data.blockData.timeselection.push({
            'BlockStart': currentTime.format('HH:mm:ss'),
            'BlockEnd': end.format('HH:mm:ss')
          })
          currentTime.add(15, 'minutes')
        }
        this.max = 0
        this.setCurrentStarttime()
      } else {
        if (this.activeRow.data.blockData.Free === -1) {
          this.max = Number.MAX_SAFE_INTEGER
        } else if (this.activeRow.data.blockData.Free < 1) {
          this.max = 0
        } else {
          if (this.activeRow.data.assortmentData.maxpax < this.activeRow.data.blockData.Free) {
            this.max = this.activeRow.data.assortmentData.maxpax - this.activeRow.pax
          } else {
            this.max = this.activeRow.data.blockData.Free
          }
        }
      }
      //werkt niet bij producten denk ik?
      this.getTarif(id_slot);
    },
    setCurrentTarif(id_tarif) {
      // this.activeRow.price = null
      this.activeRow.id_tarif = id_tarif
      this.activeRow.data.tarifData = this.tarifs.filter(item => parseInt(item.id_tarif) === parseInt(id_tarif))[0]
      if(this.activeRow.data.tarifData.price !== this.activeRow.price && this.activeRow.price !== null ){
        return
      } else {
        this.activeRow.price = this.activeRow.data.tarifData.price
      }
    },
    setCurrentStarttime() {
      const chosenTimeSlot = this.activeRow.data.blockData.timeselection.filter(item => item.BlockStart === this.activeRow.starttime)[0]
      this.activeRow.endtime = chosenTimeSlot.BlockEnd

      this.getStockDetails(chosenTimeSlot.BlockStart.substring(0, 5))
    },
    chgPax(direction) {
      if (this.activeRow.isEditable) {
        this.setDirty()
        if (direction === "up") {

          this.activeRow.pax = this.activeRow.pax + 1

          if (this.activeRow.pax > this.activeRow.data.assortmentData.maxpax) {
            this.activeRow.pax = this.activeRow.data.assortmentData.maxpax
            return
          }

          if (this.activeRow.pax > (this.activeRow.orgPax + this.max)) {
            this.activeRow.pax = (this.activeRow.orgPax + this.max)
            return
          }
          return
        }

        if (direction === "down") {
          if (this.activeRow.pax < 2) {
            this.activeRow.pax = 1
            return
          }

          this.activeRow.pax = this.activeRow.pax - 1
          return
        }
      }
    },

    //ROW Events
    async editRow(index) {
      if (!this.hasReservationCrudRights)
        return;
      if (this.mustConfirmChangesId !== null) {
        return
      }
      if (this.isEditing) {
        if (this.newElisRow) {
          const idx = index;
          setTimeout(() => {
            this.editRow(idx)
          }, 200)
        }
        this.newElisRow = false
        return
      }
      this.newElisRow = false

      this.activeRow = JSON.parse(JSON.stringify(this.orderLines[index]))

      if (this.activeRow.id_assortiment && this.activeRow.id_assortiment !== null) {
        await this.getBlokken(this.activeRow.id_assortiment, this.activeRow.date)
        if(this.reservation.isSubReservation && this.activeRow.id_assortiment !== 8654)
        this.$set(this.activeRow, 'warning', this.$t('resdetails.blokkeerdomsubres'))
        this.orderLines[index].edit = true
        this.isEditing = true
      } else {
        setTimeout(() => {
          this.orderLines[index].edit = true
          this.isEditing = true
        }, 300)
      }

    },
    async onEditEndRow() {
      if (this.isSaving) {
        return
      }
      this.isSaving = true
      if (this.isDirty) {
        const index = this.activeRow.id
        this.activeRow.dirty = true
        this.orderLines[index] = JSON.parse(JSON.stringify(this.activeRow))
        this.mustConfirmChangesId = index
      }
      await this.clearActiveRows()
      this.isSaving = false
    },

    onClickOutsideRow(e) {
      if (this.mustConfirmChangesId !== null) {
        return
      }
      let clickedElementIsNotFormElementFromThisComponent = true
      for (const key in e.path) {
        const classname = e.path[key].className
        if (typeof classname === 'string' && classname.includes('rowEl')) {
          clickedElementIsNotFormElementFromThisComponent = false
        }
        if (typeof classname === 'string' && classname.includes('orderline')) {
          this.newElisRow = true
        }
      }
      if (clickedElementIsNotFormElementFromThisComponent) {
        if (this.isEditing) {
          if (this.isDirty) {
            //console.log('was aan het editten en die was dirty dus saven!')
            this.onEditEndRow()
          } else {
            //console.log('was aan het editten en die was NIET dirty dus cleanen!')
            this.clearActiveRows()
          }
        } else {
          //console.log('not editing')
        }
      } else {
        //console.log('form elemeent op deze regel, niks doen')
      }

    },
    removeRow(index) {
      this.clearActiveRows()
      this.orderLines[index].deleteMe = true
      this.mustConfirmChangesId = index
    },

    //modalDiscount
    resetModalDiscount() {
      this.dccode = null
      this.dccodeResult = null
      this.dccOk = false
    },
    async handleModalDiscount(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.dccInputValid) {
        return
      }
      if (this.dccOk) {
        try {
          const d = await ReservationsService.addDiscountCode(this.$props.reservation.shophid, this.$props.reservation.hash, this.dccode)
          if (d && d.data && d.data.status) {
            if (d.data.status !== 'OK') {
              this.toast('notice', `${this.$t('resdetails.kortingscode')} ${d.data.status}`)
            }
          } else {
            this.toast('error', JSON.stringify(d))
          }
        } catch (e) {
          this.toast('error', JSON.stringify(e))
        }
        this.$emit('reservationsOverviewRefresh')
        this.$bvModal.hide('modal-discountcode')
      } else {
        try {
          const d = await ShopsService.getDiscountCode(this.$props.reservation.shophid, this.dccode)
          if (d && d.data && d.data.data) {
            this.dcccodeClass = d.data.status === 'ERROR' ? 'danger' :
                d.data.status === 'NOT_FOUND' ? 'warning' :
                    d.data.status === 'OK' ? 'success' : 'info'
            if (d.data.status === 'OK') {
              this.dccOk = true
              let prefix = ''
              let suffix = ''
              if (d.data.data.korting_type === "1") {
                prefix = `${this.$props.reservation.value.currency_sign} `
                suffix = ''
              } else {
                prefix = ''
                suffix = ' %'
              }
              this.dccodeResult = `${prefix}${d.data.data.korting_waarde}${suffix}`
            } else {
              this.dccodeResult = d.data.data
            }
          }
        } catch (e) {
          this.dcccodeClass = 'danger'
          this.dccodeResult = e.toString()
        }
      }
    },

    //modalBorg
    resetModalBorg() {
      this.borgAmount = 0
    },
    async handleModalBorg(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.hasBorg) {
        try {
          const d = await ReservationsService.removeBorg(this.$props.reservation.shophid, this.$props.reservation.hash)
          if (d && d.data && d.data.status) {
            if (d.data.status !== 'OK') {
              this.toast('notice', `${d.data.data}`)
            } else {
              await this.$store.dispatch("bus/setBorgRefund", d.data.data.amount)
              setTimeout(() => {
                this.$root.$emit('bv::show::modal', 'paymentModal')
              }, 300)
            }
          } else {
            this.toast('error', JSON.stringify(d))
          }
        } catch (e) {
          this.toast('error', JSON.stringify(e))
        }
      } else {
        if (!this.borgInputValid) {
          return
        }
        try {
          const d = await ReservationsService.addBorg(this.$props.reservation.shophid, this.$props.reservation.hash, (this.borgAmount * 100))
          if (d && d.data && d.data.status) {
            if (d.data.status !== 'OK') {
              this.toast('notice', `${d.data.data}`)
            } else {
              setTimeout(() => {
                this.$root.$emit('bv::show::modal', 'paymentModal')
              }, 300)
            }
          } else {
            this.toast('error', JSON.stringify(d))
          }
        } catch (e) {
          this.toast('error', JSON.stringify(e))
        }
      }
      this.$emit('reservationsOverviewRefresh')
      this.$bvModal.hide('modal-borg')
    },
    //modalAddRow
    resetModalAddRow() {

    },
    async newRowsAdded(newrows) {
      for (const row in newrows) {
        newrows[row].orgId = 0
        newrows[row].data.assortmentData = {weergavenaam: ''}
        newrows[row].data.blockData = {
          Blockname: ''
        }
        newrows[row].data.tarifData = {
          description: ''
        }
        this.orderLines.push(newrows[row])
      }
      await this.saveToBackend()
      this.$bvModal.hide('modal-addrow')
    },
    //helpers
    clearActiveRows() {
      return new Promise((resolve) => {
        if (this.isEditing) {
          this.isEditing = false
          this.isDirty = false
          this.activeRow = null
          this.slots = []
          this.tarifs = []
          for (const row in this.orderLines) {
            this.$set(this.orderLines[row], 'edit', false)
          }
          this.$nextTick(() => {
            resolve()
          })
        } else {
          resolve()
        }

      })
    },
    saveToBackend() {
      const o = this.orderLines.map((item) => {
        if (!item.deleteMe) {
          item.id = item.orgId
          return {
            id: item.id,
            id_assortiment: item.id_assortiment,
            id_block: item.id_block,
            id_tarif: item.id_tarif,
            date: item.date,
            starttime: item.starttime,
            endtime: item.endtime,
            pax: item.pax,
            price: item.price
          }
        }
      }).filter(item => item != null)

      this.updateLoadTimestamp(10);
      ReservationsService.setdetails(
          this.$props.reservation.shophid,
          this.$props.reservation.hash,
          o
      )
          .then((res) => {
            if (res.data.status && res.data.status === 'OK') {
              this.mustConfirmChangesId = null
              this.$emit('reservationsOverviewRefresh')
            } else if (res.data.status && res.data.status === 'NOTICE') {
              const h = this.$createElement
              const msg = []
              for (const row in res.data.data) {
                const ul = []
                for (const message in res.data.data[row]) {
                  ul.push(h('li', `${message} : ${res.data.data[row][message]}`))
                }
                msg.push([
                  h('b', row),
                  h('ul', ul)
                ])
              }
              this.toast('notice', h(
                  'p',
                  msg
              ), 'Opslaan mislukt')
              this.resetToOriginal()
            } else {
              this.toast('err', res.data.errormessage, 'Opslaan mislukt')
              this.resetToOriginal()
            }
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.data && error.response.data.data.errormessage) {
              this.toast('err', error.response.data.data.errormessage, 'Opslaan mislukt')
              this.resetToOriginal()
            } else {
              this.toast('err', 'Onbekende fout!', 'Opslaan mislukt')
              console.log(error.response);
              this.resetToOriginal()
            }
          })
          .finally(() => {
            this.mustConfirmChangesId = null;
          })
    },
    resetToOriginal() {
      this.orderLines = JSON.parse(JSON.stringify(this.orderLinesOrg))
      this.mustConfirmChangesId = null
    },
    islargeCard(row){
      if (row.id_assortiment === 4582 || row.id_assortiment === 1161 || row.id_assortiment === 8654) {
        return false
      }
      if (row.data && row.data.assortmentData && parseInt(row.data.assortmentData.FKid_soort) === 5) {
        return false
      }
      return true
    },
    showField(row, field) {
      if (row.id_assortiment === 4582 || row.id_assortiment === 1161 || row.id_assortiment === 8654) {
        switch (field){
          case 'activity':
          case 'total':
            return true
          default:
            return false
        }
      }
      if (row.data && row.data.assortmentData && parseInt(row.data.assortmentData.FKid_soort) === 5) {
        switch (field){
          case 'date':
          case 'activity':
          case 'price':
          case 'pax':
          case 'total':
            return true
          default:
            return false
        }
      }
      return true
    },
    async checkDetailChange() {
      try{
        const d = await ReservationsService.hasChanges(this.reservation.hash, this.loadTimestamp)
        if (d && d.data && d.data.status) {
          if (d.data.status === 'OK') {
            if (d.data.data) {
              this.toast('notice', this.$t('reslijst.resgewijzigd'));
              this.$emit('reservationsOverviewRefresh')
              this.updateLoadTimestamp(10);
            }
          }
        }else{
          this.toast('err', 'Invalid Api response')
        }
      } catch (err) {
        this.toast('err', err)
      }
    },
    updateLoadTimestamp(extraSeconds = 0) {
      this.loadTimestamp = parseInt(((Date.now() / 1000) + extraSeconds).toString());
    },
    startDetailChangeCheck() {
      this.updateLoadTimestamp();
      this.detailCheckInterval = setInterval(() => {
        this.checkDetailChange();
      }, 5000);
    }
  },
  destroyed() {
    clearInterval(this.detailCheckInterval);
  },
  filters: {},
  watch: {
    reservation: function () {
      this.fillOrderlines()
    },
  },
  created() {
    this.getAssortiment()
    this.fillOrderlines()
  },
  mounted() {
    const self = this
    this.$root.$on('bv::modal::shown', function (e) {
      const ref = `${e.componentId}-input`
      this.$nextTick(() => {
        const i = self.$refs[ref]
        if (i) i.focus()
      })
    })
    this.startDetailChangeCheck();
  }
}
</script>
<style lang="scss" scoped>

.text-no-break {
  height: 25px;
  overflow: hidden;
}

.striped:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.pl-3half {
  padding-left: 1.25rem;
}

.px-3half {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.border-grey {
  border-color: rgba(0, 0, 0, 0.125);
}

.prepend {
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.hover:hover {
  background-color: #2499a91A;
  cursor: pointer;
}

#ReservationDetails {
  .card {
    .card-body {
      padding: 0.5rem;
    }
  }
  //&.theme-light{
  //  .card {
  //    .card-body {
  //      background-color: $white;
  //    }
  //  }
  //}
  //&.theme-dark{
  //  .card {
  //    .card-body {
  //      background-color: $lk_grey_1;
  //    }
  //  }
  //}
}
.orderline {
  &.largeCard {
    min-height: 25.7rem;
    .card{
      margin-bottom: 0 !important;
    }
  }
  .field {
    justify-content: flex-start;
    width: 100%;
    display: flex;
    height: 2.5rem;
  }
  .orderline-label{
    .small{
      font-size: 70%;
    }
    width: 8rem;
    color: #666666;
  }
  .orderline-input{
    width: 100%;
    font-weight:700;
    color: #666666;
  }
  .card-body {
    padding: 1rem !important;
  }
  .card-footer {
    height: 3.5rem;
    padding: 0.3rem !important;
  }
}

@include media-breakpoint-only(xs) {
  .card-columns {
    columns: 1;
  }
}

@include media-breakpoint-only(sm) {
  .card-columns {
    columns: 1;
  }
}

@include media-breakpoint-only(md) {
  .card-columns {
    columns: 1;
  }
}

@include media-breakpoint-only(lg) {
  .card-columns {
    columns: 2;
  }
}

@include media-breakpoint-only(xl) {
  .card-columns {
    columns: 3;
  }
}

@media (min-width: 1600px) {
  .card-columns {
    columns: 4;
  }
}

.spantoggle {
  &:focus {
    outline: none;
  }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
